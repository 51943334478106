<template>
    
      <div class="row wrap center w700">
        
        <p :style="{zIndex: 1}" @click="show.email     = true" class="tag" :class="{active: active.email}"    :title="$t('hint.hooks.email')">{{hooks.email}}</p>
        <p :style="{zIndex: 6}" @click="show.airtable  = true" class="tag" :class="{active: active.airtable}"  :title="$t('hint.hooks.airtable')"><span v-if="!active.airtable">+</span>{{$t('hooks.airtable')}}</p>
        <p :style="{zIndex: 2}" @click="show.webhook   = true" class="tag" :class="{active: active.webhook}"  :title="$t('hint.hooks.webhook')"><span v-if="!active.webhook">+</span>{{$t('hooks.webhook')}}</p>
        <!-- <p :style="{zIndex: 3}" @click="show.copy      = true" class="tag" :class="{active: active.copy}"     :title="$t('hint.hooks.copy')"><span v-if="!active.copy">+</span>{{$t('hooks.copy')}}</p> -->
        <!-- <p :style="{zIndex: 4}" @click="show.bot       = true" class="tag" :class="{active: active.bot}"      :title="$t('hint.hooks.bot')"><span v-if="!active.bot">+</span>{{$t('hooks.bot')}}</p> -->
        <!-- <p :style="{zIndex: 5}" @click="show.group     = true" class="tag" :class="{active: active.group}"    :title="$t('hint.hooks.group')"><span v-if="!active.group">+</span>{{$t('hooks.group')}}</p> -->
        
        
        
        
        <!-- email -->
        <Popup v-model="show.email">
            <template #icon><svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.7493 12.7223C32.8308 11.7592 43.9749 11.7592 55.0564 12.7223L60.399 13.182C62.1121 13.3311 63.7598 13.9103 65.1895 14.8659C66.6191 15.8214 67.7844 17.1224 68.5773 18.6483C68.6699 18.8366 68.6883 19.0528 68.6286 19.254C68.569 19.4551 68.4358 19.6265 68.2555 19.7338L46.1003 33.4527C43.7685 34.8963 41.0861 35.6743 38.3437 35.7023C35.6014 35.7304 32.9036 35.0074 30.5428 33.6118L8.2426 20.4304C8.06682 20.331 7.93304 20.1712 7.86607 19.9807C7.7991 19.7902 7.80349 19.5819 7.87841 19.3944C8.60073 17.6809 9.77706 16.1971 11.2806 15.103C12.7841 14.0089 14.5577 13.3459 16.4103 13.1855L21.7493 12.7223Z" fill="#FFB800"/><path d="M7.8611 26.3719C7.73476 26.2971 7.59165 26.2551 7.4449 26.25C7.29814 26.2448 7.15244 26.2766 7.02117 26.3424C6.8899 26.4083 6.77725 26.506 6.69358 26.6266C6.6099 26.7473 6.55787 26.8871 6.54226 27.0331C5.6439 35.9356 5.86246 44.9154 7.19284 53.7636C7.53524 56.0308 8.63069 58.1167 10.3028 59.6855C11.9749 61.2544 14.1263 62.2148 16.4106 62.4122L21.7497 62.8754C32.8344 63.8406 43.9792 63.8406 55.0639 62.8754L60.4029 62.4157C62.6882 62.2171 64.84 61.2548 66.5115 59.6839C68.1831 58.113 69.2771 56.0251 69.6172 53.7566C70.9862 44.6507 71.1776 35.4064 70.1864 26.2517C70.1693 26.1051 70.1154 25.9651 70.0298 25.8449C69.9442 25.7246 69.8296 25.6279 69.6967 25.5638C69.5638 25.4996 69.4168 25.47 69.2694 25.4778C69.122 25.4855 68.9789 25.5304 68.8534 25.6082L48.8939 37.9622C45.74 39.9141 42.1121 40.966 38.4033 41.004C34.6944 41.0421 31.0457 40.0647 27.8524 38.1779L7.8611 26.3719Z" fill="#FFB800"/></svg></template>
            <template #title>{{$t('hooks.email')}}</template>
            <template #line>{{$t('line.hooks.email')}}</template>
            <template #desc>{{ $t('desc.hooks.email') }}</template>          
            <div class="column w400 center">
                <input v-model="hooks.email" class="input" style="text-align: center;" type="text">
                <br>
                <p class="button compact white" @click="updateEmail">{{$t('buttons.submit')}}</p>                
            </div>              
        </Popup>

        <!-- webhook -->
        <Popup v-model="show.webhook">
            <template #icon><svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2020_576)"><path d="M59.875 20.3125H55.1875C46.5938 20.3125 39.5625 27.3438 39.5625 35.9375C39.5625 44.5312 46.5938 51.5625 55.1875 51.5625H59.875C68.4688 51.5625 75.5 44.5312 75.5 35.9375C75.5 27.3438 68.4688 20.3125 59.875 20.3125ZM59.875 45.3125H55.1875C50.0313 45.3125 45.8125 41.0937 45.8125 35.9375C45.8125 30.7813 50.0313 26.5625 55.1875 26.5625H59.875C65.0312 26.5625 69.25 30.7813 69.25 35.9375C69.25 41.0937 65.0312 45.3125 59.875 45.3125ZM20.8125 20.3125H16.125C7.53125 20.3125 0.5 27.3438 0.5 35.9375C0.5 44.5312 7.53125 51.5625 16.125 51.5625H20.8125C29.4062 51.5625 36.4375 44.5312 36.4375 35.9375C36.4375 27.3438 29.4062 20.3125 20.8125 20.3125ZM20.8125 45.3125H16.125C10.9688 45.3125 6.75 41.0937 6.75 35.9375C6.75 30.7813 10.9688 26.5625 16.125 26.5625H20.8125C25.9687 26.5625 30.1875 30.7813 30.1875 35.9375C30.1875 41.0937 25.9687 45.3125 20.8125 45.3125Z" fill="#1976D2"/><path d="M52.0625 32.8125H23.9375C22.2187 32.8125 20.8125 34.2187 20.8125 35.9375C20.8125 37.6563 22.2187 39.0625 23.9375 39.0625H52.0625C53.7813 39.0625 55.1875 37.6563 55.1875 35.9375C55.1875 34.2187 53.7813 32.8125 52.0625 32.8125Z" fill="#42A5F5"/></g><defs><clipPath id="clip0_2020_576"><rect width="75" height="75" fill="white" transform="translate(0.5)"/></clipPath></defs></svg></template>
            <template #title>{{$t('hooks.webhook')}}</template>
            <template #line>{{$t('line.hooks.webhook.intro')}}</template>
            <template #desc>{{ $t('desc.hooks.webhook.intro') }}</template>          
            <div class="column center w400">                                
              <input class="input" type="text" v-model="hooks.webhook" placeholder="https://site.com/newlead">                
              <br>
              <p class="line link" @click="show.webhookHelp = true">Learn about a webhook</p>
               <!-- help -->
               <Popup scroll v-model="show.webhookHelp">
                <template #icon><svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2020_576)"><path d="M59.875 20.3125H55.1875C46.5938 20.3125 39.5625 27.3438 39.5625 35.9375C39.5625 44.5312 46.5938 51.5625 55.1875 51.5625H59.875C68.4688 51.5625 75.5 44.5312 75.5 35.9375C75.5 27.3438 68.4688 20.3125 59.875 20.3125ZM59.875 45.3125H55.1875C50.0313 45.3125 45.8125 41.0937 45.8125 35.9375C45.8125 30.7813 50.0313 26.5625 55.1875 26.5625H59.875C65.0312 26.5625 69.25 30.7813 69.25 35.9375C69.25 41.0937 65.0312 45.3125 59.875 45.3125ZM20.8125 20.3125H16.125C7.53125 20.3125 0.5 27.3438 0.5 35.9375C0.5 44.5312 7.53125 51.5625 16.125 51.5625H20.8125C29.4062 51.5625 36.4375 44.5312 36.4375 35.9375C36.4375 27.3438 29.4062 20.3125 20.8125 20.3125ZM20.8125 45.3125H16.125C10.9688 45.3125 6.75 41.0937 6.75 35.9375C6.75 30.7813 10.9688 26.5625 16.125 26.5625H20.8125C25.9687 26.5625 30.1875 30.7813 30.1875 35.9375C30.1875 41.0937 25.9687 45.3125 20.8125 45.3125Z" fill="#1976D2"/><path d="M52.0625 32.8125H23.9375C22.2187 32.8125 20.8125 34.2187 20.8125 35.9375C20.8125 37.6563 22.2187 39.0625 23.9375 39.0625H52.0625C53.7813 39.0625 55.1875 37.6563 55.1875 35.9375C55.1875 34.2187 53.7813 32.8125 52.0625 32.8125Z" fill="#42A5F5"/></g><defs><clipPath id="clip0_2020_576"><rect width="75" height="75" fill="white" transform="translate(0.5)"/></clipPath></defs></svg></template>
                      <template #title>{{$t('title.hooks.webhook.easy')}}</template>
                      <template #line>{{$t('line.hooks.webhook.api')}}</template>
                      <!-- <template #desc>{{$t('desc.hooks.webhook.api')}}</template> -->
                        <div class="column center w600">
                          
                          <div class="block">
                            <h4>1. You've got a new lead</h4>
                            <p class="w500 text mb2">{{ $t('desc.hooks.webhook.how') }}</p>                            
                          </div>
                          
                          <div class="block">
                            <h4>2. We make a HTTP request to your server</h4>
                            <p class="w500 text mb2">{{ $t('desc.hooks.webhook.req') }}</p>                            
                            <highlightjs  style="text-align: left; font-size: 13px; border-radius: 15px; width: 500px;" language="bash" :code="httpreq"/>                            
                          </div>
                          <div class="block">
                            <h4>3. Your server is processing the request</h4>
                            <p class="w500 text mb2">{{ $t('desc.hooks.webhook.once') }}</p>                            
                          </div>
                          
                          
                              
                        </div>              
                </Popup>

               
                <br>
                <p v-if="hooks.webhook" class="button compact white" @click="updateWebhook">{{$t('buttons.submit')}}</p>                
            </div>              
        </Popup>


        <!-- telegram user -->
        <Popup v-model="show.copy">
            <template #icon><svg width="74" height="73" viewBox="0 0 74 73" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2020_544)"><path d="M37 0C27.3218 0 18.0314 3.8479 11.1934 10.6905C4.34826 17.5358 0.501896 26.8194 0.5 36.5C0.5 46.1765 4.34961 55.4669 11.1934 62.3095C18.0314 69.1521 27.3218 73 37 73C46.6782 73 55.9686 69.1521 62.8066 62.3095C69.6504 55.4669 73.5 46.1765 73.5 36.5C73.5 26.8235 69.6504 17.5331 62.8066 10.6905C55.9686 3.8479 46.6782 0 37 0Z" fill="url(#paint0_linear_2020_544)"/><path d="M17.0218 36.1146C27.6638 31.4791 34.7585 28.4228 38.3059 26.9463C48.446 22.73 50.5505 21.9977 51.9249 21.9729C52.2272 21.968 52.9002 22.0427 53.3393 22.3978C53.7043 22.6972 53.807 23.1021 53.8583 23.3864C53.9039 23.6704 53.9667 24.3177 53.9153 24.823C53.3678 30.5946 50.9896 44.6003 49.7806 51.0648C49.273 53.8 48.2635 54.7171 47.2883 54.8066C45.1667 55.0017 43.5585 53.4059 41.5053 52.0606C38.2945 49.9544 36.4809 48.6438 33.3613 46.589C29.7569 44.2142 32.0952 42.9088 34.1483 40.7758C34.6844 40.2175 44.0261 31.7226 44.2029 30.9522C44.2257 30.8558 44.2485 30.4965 44.0318 30.3071C43.8208 30.1172 43.5071 30.1822 43.279 30.2336C42.9539 30.3066 37.8268 33.6988 27.8806 40.4096C26.4263 41.41 25.1088 41.8976 23.9226 41.8719C22.6223 41.844 20.1129 41.1351 18.248 40.5294C15.9667 39.7863 14.1474 39.3934 14.3071 38.1313C14.387 37.4743 15.2938 36.8019 17.0218 36.1146Z" fill="white"/></g><defs><linearGradient id="paint0_linear_2020_544" x1="3650.5" y1="0" x2="3650.5" y2="7300" gradientUnits="userSpaceOnUse"><stop stop-color="#2AABEE"/><stop offset="1" stop-color="#229ED9"/></linearGradient><clipPath id="clip0_2020_544"><rect width="73" height="73" fill="white" transform="translate(0.5)"/></clipPath></defs></svg></template>
            <template #title>{{$t('hooks.copy')}}</template>
            <template #line>{{$t('line.hooks.copy')}}</template>
            <template #desc>{{ $t('desc.hooks.copy') }}</template>          
            <div class="column center">              
                 <h4 class="w350">Open <a target="_blank" href="https://t.me/meetcopyBot?start=id">@meetcopyBot</a> and paste your number ID here</h4>          
                 <br>
                 <input class="input w300" style="text-align: center;" type="number" v-model="hooks.telegram.user" placeholder="597249692...">                
                <br>
                <p class="button compact white" @click="updateCopyBot">{{$t('buttons.submit')}}</p>                
            </div>              
        </Popup>


        <!-- tg bot -->
        <Popup v-model="show.bot">
            <template #icon><svg width="74" height="73" viewBox="0 0 74 73" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2020_544)"><path d="M37 0C27.3218 0 18.0314 3.8479 11.1934 10.6905C4.34826 17.5358 0.501896 26.8194 0.5 36.5C0.5 46.1765 4.34961 55.4669 11.1934 62.3095C18.0314 69.1521 27.3218 73 37 73C46.6782 73 55.9686 69.1521 62.8066 62.3095C69.6504 55.4669 73.5 46.1765 73.5 36.5C73.5 26.8235 69.6504 17.5331 62.8066 10.6905C55.9686 3.8479 46.6782 0 37 0Z" fill="url(#paint0_linear_2020_544)"/><path d="M17.0218 36.1146C27.6638 31.4791 34.7585 28.4228 38.3059 26.9463C48.446 22.73 50.5505 21.9977 51.9249 21.9729C52.2272 21.968 52.9002 22.0427 53.3393 22.3978C53.7043 22.6972 53.807 23.1021 53.8583 23.3864C53.9039 23.6704 53.9667 24.3177 53.9153 24.823C53.3678 30.5946 50.9896 44.6003 49.7806 51.0648C49.273 53.8 48.2635 54.7171 47.2883 54.8066C45.1667 55.0017 43.5585 53.4059 41.5053 52.0606C38.2945 49.9544 36.4809 48.6438 33.3613 46.589C29.7569 44.2142 32.0952 42.9088 34.1483 40.7758C34.6844 40.2175 44.0261 31.7226 44.2029 30.9522C44.2257 30.8558 44.2485 30.4965 44.0318 30.3071C43.8208 30.1172 43.5071 30.1822 43.279 30.2336C42.9539 30.3066 37.8268 33.6988 27.8806 40.4096C26.4263 41.41 25.1088 41.8976 23.9226 41.8719C22.6223 41.844 20.1129 41.1351 18.248 40.5294C15.9667 39.7863 14.1474 39.3934 14.3071 38.1313C14.387 37.4743 15.2938 36.8019 17.0218 36.1146Z" fill="white"/></g><defs><linearGradient id="paint0_linear_2020_544" x1="3650.5" y1="0" x2="3650.5" y2="7300" gradientUnits="userSpaceOnUse"><stop stop-color="#2AABEE"/><stop offset="1" stop-color="#229ED9"/></linearGradient><clipPath id="clip0_2020_544"><rect width="73" height="73" fill="white" transform="translate(0.5)"/></clipPath></defs></svg></template>
            <template #title>{{$t('hooks.bot')}}</template>
            <template #line>{{$t('line.hooks.bot')}}</template>
            <template #desc>{{ $t('desc.hooks.bot') }}</template>          
            <div class="column center">              
                 <h4 class="w350">{{ $t('hooks.pasteToken') }}</h4>                           
                 <input class="input w500" style="text-align: center;" type="text" v-model="hooks.telegram.bot" placeholder="1469411010:AAH9VIITОВB4fJcyDfIgbxrqUk1g...">                
                <br>
                <p class="button compact white" @click="updateBot">{{$t('buttons.submit')}}</p>                
            </div>              
        </Popup>


         <!-- tg group -->
         <Popup v-model="show.group">
            <template #icon><svg width="74" height="73" viewBox="0 0 74 73" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2020_544)"><path d="M37 0C27.3218 0 18.0314 3.8479 11.1934 10.6905C4.34826 17.5358 0.501896 26.8194 0.5 36.5C0.5 46.1765 4.34961 55.4669 11.1934 62.3095C18.0314 69.1521 27.3218 73 37 73C46.6782 73 55.9686 69.1521 62.8066 62.3095C69.6504 55.4669 73.5 46.1765 73.5 36.5C73.5 26.8235 69.6504 17.5331 62.8066 10.6905C55.9686 3.8479 46.6782 0 37 0Z" fill="url(#paint0_linear_2020_544)"/><path d="M17.0218 36.1146C27.6638 31.4791 34.7585 28.4228 38.3059 26.9463C48.446 22.73 50.5505 21.9977 51.9249 21.9729C52.2272 21.968 52.9002 22.0427 53.3393 22.3978C53.7043 22.6972 53.807 23.1021 53.8583 23.3864C53.9039 23.6704 53.9667 24.3177 53.9153 24.823C53.3678 30.5946 50.9896 44.6003 49.7806 51.0648C49.273 53.8 48.2635 54.7171 47.2883 54.8066C45.1667 55.0017 43.5585 53.4059 41.5053 52.0606C38.2945 49.9544 36.4809 48.6438 33.3613 46.589C29.7569 44.2142 32.0952 42.9088 34.1483 40.7758C34.6844 40.2175 44.0261 31.7226 44.2029 30.9522C44.2257 30.8558 44.2485 30.4965 44.0318 30.3071C43.8208 30.1172 43.5071 30.1822 43.279 30.2336C42.9539 30.3066 37.8268 33.6988 27.8806 40.4096C26.4263 41.41 25.1088 41.8976 23.9226 41.8719C22.6223 41.844 20.1129 41.1351 18.248 40.5294C15.9667 39.7863 14.1474 39.3934 14.3071 38.1313C14.387 37.4743 15.2938 36.8019 17.0218 36.1146Z" fill="white"/></g><defs><linearGradient id="paint0_linear_2020_544" x1="3650.5" y1="0" x2="3650.5" y2="7300" gradientUnits="userSpaceOnUse"><stop stop-color="#2AABEE"/><stop offset="1" stop-color="#229ED9"/></linearGradient><clipPath id="clip0_2020_544"><rect width="73" height="73" fill="white" transform="translate(0.5)"/></clipPath></defs></svg></template>
            <template #title>{{$t('hooks.group')}}</template>
            <template #line>{{$t('line.hooks.group')}}</template>
            <template #desc>{{ $t('desc.hooks.group') }}</template>          
            <div class="column center">              
              <h4>1. Add <a target="_blank" href="https://t.me/meetcopyBot">@meetcopyBot</a> to your group.</h4>
              <p class="desc w300">Just add our official bot to your private group. Set post permissions.</p>
              <br>
              <h4 class="w350">2. Find your group ID</h4>
              <p class="desc w500">You need to log into your telegram account through the telegram web client. Then click on the group and in the address bar you will see the ID of your group (usually it starts with a minus)</p>
              <br>
              <h4 class="w350">3. Paste your group ID</h4>
                 <input class="input w500" style="text-align: center;" type="number" v-model="hooks.telegram.group" placeholder="-140509802840724">                
                <br>
                <p class="button compact white" @click="updateGroup">{{$t('buttons.submit')}}</p>                
            </div>              
        </Popup>


         <!-- airtable -->
         <Popup v-model="show.airtable">
            <template #icon><svg width="90" height="75" viewBox="0 0 90 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M41.059 7.44596L13.7266 18.7558C12.2067 19.3847 12.2224 21.5435 13.7521 22.15L41.1985 33.0341C43.61 33.9905 46.2957 33.9905 48.7072 33.0341L76.1542 22.1497C77.6833 21.5438 77.6993 19.3849 76.1788 18.7558L48.847 7.44568C46.3535 6.41394 43.5525 6.41423 41.059 7.44596Z" fill="#FFBF00"/><path d="M47.388 38.9776V66.1676C47.388 67.4609 48.6916 68.3464 49.8938 67.87L80.4777 55.9986C80.8187 55.8634 81.1113 55.6288 81.3174 55.3253C81.5236 55.0218 81.6338 54.6634 81.6337 54.2965V27.1062C81.6337 25.813 80.3298 24.9277 79.1277 25.4041L48.5437 37.2752C48.2027 37.4105 47.9101 37.6451 47.704 37.9487C47.4979 38.2522 47.3877 38.6107 47.3877 38.9776" fill="#26B5F8"/><path d="M40.2466 40.3829L31.1701 44.7652L30.2484 45.2108L11.088 54.3913C9.87352 54.9772 8.32324 54.0922 8.32324 52.743V27.2225C8.32324 26.7343 8.57365 26.3128 8.90943 25.9954C9.047 25.8585 9.20303 25.7415 9.373 25.6479C9.83084 25.3731 10.4841 25.2998 11.0393 25.5195L40.0939 37.0313C41.5708 37.6172 41.6868 39.6869 40.2466 40.3829Z" fill="#ED3049"/><path d="M40.2466 40.3826L31.1701 44.765L8.90918 25.9954C9.04675 25.8585 9.20278 25.7415 9.37275 25.6479C9.83058 25.3731 10.4838 25.2998 11.0391 25.5195L40.0936 37.0313C41.5705 37.6172 41.6869 39.6866 40.2466 40.3826Z" fill="black" fill-opacity="0.25"/></svg></template>
            <template #title>Airtable</template>
            <template #line>{{$t('line.hooks.airtable.intro')}}</template>
            <template #desc>{{ $t('desc.hooks.airtable.intro') }}</template>          
            <div class="column center">              
              
                <input class="input w400" type="text" v-model="hooks.airtable.token" :placeholder="$t('placeholder.hooks.personaltoken')">   
                
                <!-- select db -->
                <Popup scroll v-model="show.airtableDatabases">
                  <template #icon><svg width="90" height="75" viewBox="0 0 90 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M41.059 7.44596L13.7266 18.7558C12.2067 19.3847 12.2224 21.5435 13.7521 22.15L41.1985 33.0341C43.61 33.9905 46.2957 33.9905 48.7072 33.0341L76.1542 22.1497C77.6833 21.5438 77.6993 19.3849 76.1788 18.7558L48.847 7.44568C46.3535 6.41394 43.5525 6.41423 41.059 7.44596Z" fill="#FFBF00"/><path d="M47.388 38.9776V66.1676C47.388 67.4609 48.6916 68.3464 49.8938 67.87L80.4777 55.9986C80.8187 55.8634 81.1113 55.6288 81.3174 55.3253C81.5236 55.0218 81.6338 54.6634 81.6337 54.2965V27.1062C81.6337 25.813 80.3298 24.9277 79.1277 25.4041L48.5437 37.2752C48.2027 37.4105 47.9101 37.6451 47.704 37.9487C47.4979 38.2522 47.3877 38.6107 47.3877 38.9776" fill="#26B5F8"/><path d="M40.2466 40.3829L31.1701 44.7652L30.2484 45.2108L11.088 54.3913C9.87352 54.9772 8.32324 54.0922 8.32324 52.743V27.2225C8.32324 26.7343 8.57365 26.3128 8.90943 25.9954C9.047 25.8585 9.20303 25.7415 9.373 25.6479C9.83084 25.3731 10.4841 25.2998 11.0393 25.5195L40.0939 37.0313C41.5708 37.6172 41.6868 39.6869 40.2466 40.3829Z" fill="#ED3049"/><path d="M40.2466 40.3826L31.1701 44.765L8.90918 25.9954C9.04675 25.8585 9.20278 25.7415 9.37275 25.6479C9.83058 25.3731 10.4838 25.2998 11.0391 25.5195L40.0936 37.0313C41.5705 37.6172 41.6869 39.6866 40.2466 40.3826Z" fill="black" fill-opacity="0.25"/></svg></template>
                  <template #title>{{$t('title.hooks.airtable.select')}}</template>
                  <template #line>{{$t('line.hooks.airtable.db')}}</template>
                  <template #desc>{{$t('desc.hooks.airtable.db')}}</template>
                    <div class="column center" style="max-width: 800px;">
                      <div class="row wrap">
                        <p class="tag" @click="hooks.airtable.base = item.id; airtable.current=item; show.airtableDatabases = false" 
                        :class="{active: hooks.airtable.base == item.id}" 
                        v-for="(item, index) in airtables" :key="index">{{ item.name }}</p>                    
                      </div>                      
                    </div>              
                </Popup>
              
                
                <br>
                <!-- no token -->
                <div class="column center" v-if="!hooks.airtable.token">                  
                  <p class="line link" @click="show.airtableFaq = true">{{ $t('desc.hooks.airtable.help') }} </p>
                  
                  <!-- help -->
                    <Popup scroll v-model="show.airtableFaq">
                      <template #icon><svg width="90" height="75" viewBox="0 0 90 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M41.059 7.44596L13.7266 18.7558C12.2067 19.3847 12.2224 21.5435 13.7521 22.15L41.1985 33.0341C43.61 33.9905 46.2957 33.9905 48.7072 33.0341L76.1542 22.1497C77.6833 21.5438 77.6993 19.3849 76.1788 18.7558L48.847 7.44568C46.3535 6.41394 43.5525 6.41423 41.059 7.44596Z" fill="#FFBF00"/><path d="M47.388 38.9776V66.1676C47.388 67.4609 48.6916 68.3464 49.8938 67.87L80.4777 55.9986C80.8187 55.8634 81.1113 55.6288 81.3174 55.3253C81.5236 55.0218 81.6338 54.6634 81.6337 54.2965V27.1062C81.6337 25.813 80.3298 24.9277 79.1277 25.4041L48.5437 37.2752C48.2027 37.4105 47.9101 37.6451 47.704 37.9487C47.4979 38.2522 47.3877 38.6107 47.3877 38.9776" fill="#26B5F8"/><path d="M40.2466 40.3829L31.1701 44.7652L30.2484 45.2108L11.088 54.3913C9.87352 54.9772 8.32324 54.0922 8.32324 52.743V27.2225C8.32324 26.7343 8.57365 26.3128 8.90943 25.9954C9.047 25.8585 9.20303 25.7415 9.373 25.6479C9.83084 25.3731 10.4841 25.2998 11.0393 25.5195L40.0939 37.0313C41.5708 37.6172 41.6868 39.6869 40.2466 40.3829Z" fill="#ED3049"/><path d="M40.2466 40.3826L31.1701 44.765L8.90918 25.9954C9.04675 25.8585 9.20278 25.7415 9.37275 25.6479C9.83058 25.3731 10.4838 25.2998 11.0391 25.5195L40.0936 37.0313C41.5705 37.6172 41.6869 39.6866 40.2466 40.3826Z" fill="black" fill-opacity="0.25"/></svg></template>
                      <template #title>{{$t('title.hooks.airtable.token')}}</template>
                      <template #line>{{$t('line.hooks.airtable.token')}}</template>
                      <template #desc>{{$t('desc.hooks.airtable.token')}}</template>
                        <div class="column center" style="max-width: 600px;">
                          
                          <div class="block">
                            <h4>1. Create a token</h4>
                            <p class="w500 text mb2">Open your <a target="_blank" href="https://airtable.com/create/tokens">AirTable account</a> and click on</p>
                            <li class="text code spandex">+Create new token</li>
                          </div>
                          
                          <div class="block">
                            <h4>2. Add a scope</h4>
                            <p class="w500 text mb2">Click +Add scopes and select this 4 rules:</p>
                            <li class="text code spandex">data.records:read</li>
                            <li class="text code spandex">data.records:write</li>
                            <li class="text code spandex">schema.bases:read</li>
                            <li class="text code spandex">schema.bases:write</li>
                          </div>
                          
                          <div class="block">
                            <h4>3. Add a base</h4>
                            <p class="w500 text">Then click on</p>                                                        
                            <li class="text code spandex">+Add a base</li>
                            <p class="w500 text">and select your database for Copy leads. Then just copy paste your access token to copy settings</p>                            
                          </div>
                          
                          <div class="block">
                            <h4>4. Copy your new token</h4>
                            <p class="w500 text">Then paste into token input</p>                            
                          </div>
                          
                              
                        </div>              
                    </Popup>
                    
                </div>

                <!-- token, no db -->
                <div class="column center" v-if="hooks.airtable.token && !hooks.airtable.base">
                  <p class="desc w300">{{ $t('desc.hooks.airtable.db') }}</p>
                  <p class="button compact white" @click="getAirTableDatabaseList()">{{$t('title.hooks.airtable.select')}}...</p>                
                </div>

                <!-- token, db -->
                <div class="column center" v-if="hooks.airtable.token && hooks.airtable.base">
                  <h3 v-if="airtable.current" class=" mt4 pointer click" @click="getAirTableDatabaseList()">DB: {{ airtable.current.name }}</h3>    
                  <p class="desc w300">{{ $t('desc.hooks.airtable.tables') }}</p>
                  <br>            
                  <p class="button compact white" @click="updateAirTable()">{{$t('buttons.submit')}}</p>                
                </div>

            </div>              
        </Popup>
        
      </div>

      <!-- <Hooks :init="copy.hooks" @update="this.hooks = $event"/>   -->
      
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  mounted() {
    if (this.init){
      this.hooks = this.init      
    } else{
      this.hooks.email = this.db.profile.me.email
      this.update()
    }

    if (!this.hooks) this.hooks = {}
    if (!this.hooks.telegram) this.hooks.telegram = {}
    if (!this.hooks.airtable) this.hooks.airtable = {}
    
    
  },
  props:{
    init:{},
    color: {type:String, default:''}
  },
 
  data() {
    return {
      show:{        
          email:false,  
          webhook: false,    
          copy: false,
          bot: false,
          group: false,  
          airtable: false,
          
          // airtable
          airtableDatabases: false,
          airtableFaq: false,

          webhookHelp: false,
      },      
     
      hooks:{
        email:undefined,        
        webhook: undefined,
        telegram:{
          user: undefined, //tg id
          bot: undefined, //tg token
          group: undefined, //tg id        
        },                
        airtable:{
          base: undefined,     
          table: undefined,     
          token: undefined,     
        }        
      },      

      airtable:{   
        current:undefined,    
        db: []
      },
    }
  },
  computed: {
    active(){
        let active = {}
        active.email = true
        active.copy = this.hooks.telegram.user
        active.bot = this.hooks.telegram.bot
        active.group = this.hooks.telegram.group
        active.webhook = this.hooks.webhook
        active.airtable = this.hooks.airtable.token && this.hooks.airtable.base        
        return active
    },

    telegram(){
      return this.hooks.telegram || {}
    },
    http(){
      return this.hooks.http || {}
    },
    
    
    airtables(){      
      return this.airtable.db.filter(db => db.permissionLevel == 'create') || []
    },

    httpreq(){
      return `POST https://yoursite.com/newlead
{
  "id": 957971,
  "email": "tim@apple.com",
  "name": "Tim",
  "company": "Apple",
  "link": "https://apple.com",
  "copy": 341
}`
    }
    
   
  },
  methods: {
    update(){
      this.$emit('update', this.hooks)
    },

    updateEmail(){
      let valid = this.tools.valid.email(this.hooks.email)
      console.log('email valid', valid)
      if (!valid) {
        this.hooks.email = this.db.profile.me.email
        this.show.email = false
        return
      }
      this.update()
      this.show.email = false
    },

    updateWebhook(){
      let valid = this.tools.valid.link(this.hooks.webhook)
      if (!valid) {
        this.hooks.webhook = undefined
        this.show.webhook = false
        return
      }
      this.update()   
      this.show.webhook = false   
    },

    updateCopyBot(){
      let id = Number(this.hooks.copy)
      if (!id || id < 0) {
        this.hooks.copy = undefined
        alert('ID must be a number...')
        this.show.copy = false  
        return
      }      
      this.update()   
      this.show.copy = false   
    },

    updateBot(){
      if (!this.hooks.bot || !this.hooks.bot.includes(':') || this.hooks.bot.length < 20){
        alert("Its doesn't look like a telegram bot token. Ussually it looks like this: 929759791:AAH9VIITPmN2b9B4fJcyDfIhx_gbxrqUk1g")
        this.hooks.bot = undefined
        this.show.bot = false  
        return
      }

      this.update()   
      this.show.bot = false   
    },

    updateGroup(){      
      let id = Number(this.hooks.group)
      if (!id) {
        this.hooks.group = undefined
        alert('ID must be a number...')
        this.show.group = false  
        return
      }
      this.update()   
      this.show.group = false   
    },

    updateAirTable(){      
      console.log(this.hooks.airtableToken)
      console.log(this.hooks.airtableDatabase)
      
      this.update()   
      this.show.airtable = false   
    },

    async getAirTableDatabaseList(){
      let res = await this.api.hooks.airtableDatabases(this.hooks.airtable.token)
      if (res.error){
        alert("Something wrong:", res.error)
        return
      }
      if (!res.resp){
        alert("Something wrong. Please Try again lated")
        return
      }
      
      this.airtable.db = res.resp
      this.show.airtableDatabases = true
    },



  },
  
});
</script>
<style scoped>

.designOptionsInputWidth{
  width: 187px;
  height: 50px;
}
.previewImage{
    position: relative; 
    height: 400px;   
    /* filter: drop-shadow(20px 24px 34px rgba(0, 0, 0, 0.001)); */
}

.previewContent{
  position: relative; 
}

.previewShadow{
  width: 100%; height: 100%;
}

.previewRadius{
  border-radius: 30px;
}
.progress{
  user-select: none;
  opacity: .3;
  filter: grayscale(1);
}

.backHover{
  background: none;
  color: var(--g6);
  border: 6px dashed var(--g4);
  transition: all 0.2s ease;
}

.backHover.active{
  background: none;
  color: var(--g6);
  border: 1px solid var(--g1);
  
}

.backHover:hover{
  background: var(--primary);
  color: var(--g6);
}
.subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.0em;
  color: var(--g4);
  margin: 10px 0;
  /* margin-left: -5px; */

}

.tabs {
  margin: 0 10px;
}

.texttab{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: left;
  width: 300px;
  height: 270px;
  padding: 30px 30px;
  background: var(--g05);
  /* border: 1px solid var(--g1); */
  margin: 10px;
}
/* 
.texttab:first-child{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.texttab:last-child{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
} */

.texttab.active{
  background: var(--primary);
}

.texttabTitle{
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 25px;
}
.texttabDesc{
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 140%;
letter-spacing: 0.02em;
color: var(--g6);
margin-top: 8px;
}

.texttabPrice{
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 140%;
letter-spacing: 0.0em;
color: var(--g8);
margin-top: 8px;
}

.texttabSpan{
padding: 1px 5px;
background: rgba(255, 0, 230, 0.1);
border: 2px solid #000000;
box-shadow: 1px 2px 0px #000000;
border-radius: 190px;
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 123%;
color: #000000;
margin: 0 0 2px;
}

.grayBackHover:hover{
  background: var(--g05);
}

.lineCheckBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: 6px 6px 6px 6px; 
  border-bottom: 1px solid var(--g05);
  background: var(--inputBack);
  cursor: pointer;
  transition: all 0.2s ease;
}

.lineCheckBox:first-child{
  border-radius: 15px 15px 0 0;
}
.lineCheckBox:last-child{
  border-radius: 0 0 15px 15px;
}

.lineCheckBox.active, .lineCheckBox.active:hover{
  background: var(--primary);
}

.lineCheckBox.disable, .lineCheckBox.disable:hover{
  opacity: .8;
}

.lineCheckBox:hover{
  background: var(--g01);
}

.lineCheckBoxTitle{
  font-size: 18px; 
  margin-left: 10px;
  color: var(--g4);
}

.lineCheckBoxTitle.active{
  font-weight: 700;
  color: var(--text);
}

.lineCheckBoxDesc{
  font-size: 14px; 
  margin: 0 10px;
  color: var(--g3);
}



</style>