// <p v-drop="uploadHandler(files)"></p>
import { useDropZone } from '@vueuse/core';
import { ref } from 'vue';
const Drop = {
  beforeMount(el, binding) {      
      const drop = (files) => binding.value(files)
      const dropZoneRef = ref(el)
      const { isOverDropZone } = useDropZone(dropZoneRef, drop)        
      // el.addEventListener('click', handler);
 }
}
export default Drop

/* 
https://stackoverflow.com/questions/71782998/vuejs3-how-to-register-a-custom-global-directive-in-a-separate-folder

const TrackDirective = {
    beforeMount: (el, binding) => {
        el.addEventListener('click', () => {
            console.info('tracking')
        })
    }
}
export default TrackDirective


import upload from "upload.js";
app.directive('upload', upload)


<template>
  <div v-track>
    click on this text
  </div>
</template>
*/