<template>
  
  <div class="box">
    <h1 class="title"><span v-if="hash" :style="{color: color}">#</span><slot name="title"></slot></h1>
    <p class="desc w500"><slot name="desc"></slot></p>
  </div>
  
  <!-- <BlockTitle>
        <template #title>{{ title }}</template>
        <template #desc>{{ desc }}</template>
      </BlockTitle> -->

</template>
<script>
export default {
  data() {return {}},
  props:{
    copy:{},
    hash: {type: Boolean, default: true},
    color: {type:String, default:'var(--text)'},
    plus: Boolean,

  },  
  
};
</script>
<style scoped>

.box{
  margin: 0px 0px 40px; 
}
.title{
  font-family: var(--titleFonts);
  font-size: 88px; 
  font-weight: 900;
  text-align: left;
  line-height:80px;
  letter-spacing: -0.02em;
}
.desc{
 font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 17px;
letter-spacing: 0.02em;
color: var(--g5)

}

</style>