<template>
   <span>{{ ago }}</span>
</template>
<script>
export default {
    data: function () {return {}},
    props:{        
        time: Number,
        lang: {type:String, default:'en'},
    },
    methods:{
        many(num, vars) {    
            let cases = [2, 0, 1, 1, 1, 2];
            let id = (num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]
            return `${num} ${vars[id]}`
            return `${num} ${vars[id]}`
            // switch (id) {
            // case 0: return oneFiveMany[0]
            // case 1: return five
            // case 2: return many
            // }
        },
    },
   
    computed:{
        translate(){        
            let langs = {
                en:{now:'now',ago:'ago',next:'in',sec:'seconds',min:'minute',hours:'hours',days:'days',month:'months', years:'years'},
                ru:{now:'Недавно',ago:'назад',next:'через',sec:'сек',min:'мин',hours:'час',days:'д',month:'м', years:'г'},
            }
            return langs[this.db.settings.lang] || langs.en
            // return langs[this.lang] || langs.en
        },

        ago() {       
        // const text = useTimeAgo(new Date(this.time * 1000))
        // console.log('ago useVue',text.value)
        
        let translate = this.translate
        
        
        let tt = new Date()
        var sec = Math.round((tt.getTime() / 1000) - this.time)
        let back = translate.ago
        let front = translate.next;
        if (sec < 0) {
        sec = sec * -1
        back = ''
        } else {
        front = ''
        }

        if (sec < 0) return translate.now
        if (sec > 0 && sec < 60) return `${front} ${sec} ${translate.sec} ${back}`
        if (sec > 60 && sec < 60 * 60) {
        let m = Math.round(sec / 60)
        return `${front} ${m} ${translate.min} ${back}` 
        }
        if (sec >= 60 * 60 && sec < 60 * 60 * 24) {
        let m = Math.round(sec / (60 * 60))
        return `${front} ${m}${translate.hours} ${back}`
        }
        if (sec >= 60 * 60 * 24 && sec < 60 * 60 * 24 * 30) {
        let m = Math.round(sec / (60 * 60 * 24))
        return `${front} ${m} ${translate.days} ${back}`
        }
        if (sec >= 60 * 60 * 24 * 30 && sec < 60 * 60 * 24 * 365) {
        let m = Math.round(sec / (60 * 60 * 24 * 30))
        return `${front} ${m} ${translate.month} ${back}`
        }
        if (sec >= 60 * 60 * 24 * 365) {
        let m = Math.round(sec / (60 * 60 * 24 * 365))
        return `${front} ${m} ${translate.years} ${back}`
        }

        return ''
    },
    },
}
</script>