import { ProfileStore } from '@/ts/store/profile';
import { SettingsStore } from '@/ts/store/settings';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const db = ProfileStore()
const profile: any = db.me
const logged: any = profile.id

const settings = SettingsStore()

const routes: Array<RouteRecordRaw> = [  
  {path: '/',             name: 'home',     component: () => import('@/page/home.vue')},
  {path: '/session/:id',  name: 'session',  component: () => import('@/page/session.vue')},     
  {path: '/view/:id',     name: 'view',  component: () => import('@/page/view.vue')},     
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// GOOD
router.beforeEach((to, from, next) => {
  settings.resetPage()
  next()
})


function user(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id){
    next()
  } else{
    next({ name: 'home' })
  }
}


function images(to: any, from: any, next: any) {
    next(window.location)  
}

function pub(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (!profile.id){
    next()
  } else{
    next({ name: 'home'})
  }
}

function admin(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id && profile.admin){
    next()
  } else{
    next({ name: 'home'})
  }
}

function loggedNotInit(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (profile.id && !profile.name){
    next({ name: 'init' })
  } else{
    next()
  }
}

// init profile
function init(to: any, from: any, next: any) {
  const db = ProfileStore()
  const profile: any = db.me
  if (to.name=='init'){
    if (!profile.id){
      next({ name: 'welcome'})
      return
    }
    if (profile.id && profile.name){
      next({ name: 'profile'})
      return
    }
    if (profile.id && !profile.name){
      next()
      return
    }
  } else {
    if (!profile.id){
      next({ name: 'welcome'})
      return
    }
    if (profile.id && profile.name){
      next()
      return
    }
    if (profile.id && !profile.name){
      next({ name: 'init'})
      return
    }
  }
}

export default router
