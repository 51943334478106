<template>
    <box>        
        <!-- <Head/>  -->
        <div class="initbox">            
            <router-view v-slot="{ Component, route }"> 
                <!-- <Transition name="fade"> -->
                    <component :is="Component" :key="route.path" />            
                <!-- </Transition>            -->
            </router-view>
        </div>    
        <!-- <Footer/>  -->
    </box>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'App',
    
    data() {return {
        dark: true
    }},
    mounted() {
        var el = document.querySelector('body')
      el.classList.toggle('colorTransition')
      this.db.settings.dark ? this.setDark() : this.setLight()
    },
    methods: {
        setLight(){
        if (this.isDark()){
          var el = document.querySelector('body')
          
          el.classList.toggle('colorTransition')
          el.classList.toggle('dark')
          this.dark = false
          this.db.settings.dark = false
          el.classList.toggle('colorTransition')
        }
      },
      setDark(){
        if (!this.isDark()){
          var el = document.querySelector('body')
          el.classList.toggle('colorTransition')
          el.classList.toggle('dark')
          this.dark = true
          this.db.settings.dark = true
          el.classList.toggle('colorTransition')
        }
      },
        isDark(){
          return document.querySelector('body').classList.contains('dark')
        },
        change(){
          if (this.isDark()){
            this.setLight()
          } else{
            this.setDark()
          }
            // document.querySelector('body').classList.toggle('dark')
            // let dark = document.querySelector('body').classList.contains('dark')            
        }, 
    },
    });
</script>
<style>
    @import '@/css/x_reset.css';
    @import '@/css/base.css';
    @import '@/css/body.css';
    @import '@/css/buttons.css';
    @import '@/css/input.css';
    @import '@/css/tabs.css';
    @import '@/css/subtabs.css';
    @import '@/css/tag.css';
    @import '@/css/text.css';
    @import '@/css/sizes.css';
    @import '@/css/table.css';
    @import '@/css/padding.css';
    @import '@/css/local.css';
    @import '@/css/transitions.css';
    @import '@/css/colors.css'; /* must last */   
    

    .initbox{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100vw;
        min-height:100vh; 
        max-width: 100vw;
        /* overflow-y: hidden; */
    }

</style>