<template>
   <span style="position: relative">        
        <input style="position: absolute; opacity: 0;left: 50%;transform: translateX(-50%); z-index: 100; width: 100%; height: 100%" type="color" :value="modelValue" @input="update($event.target.value)">
            <slot></slot>
   </span>

    <!-- 
        <Color width="32px" height="32px" vmodel="color"/>

     -->
    
</template>
<script>
  export default {
    props: {            
      // radius: String,
      // border: String,
      modelValue: String
    },
    computed: {},
    data () {return {
      colorModel: 'white'
    }},
    methods:{
      update(v) {
        this.$emit('update:modelValue', v)
        if (this.delay > 0){
          this.change(v)
        } 
      },
    },
  }
</script>