// <p v-upload.limit30mb.txt.csv.pdf.doc.xls.jpg.png.gif.svg.zip.rar.gzip.docs.images.video.audio.archive.one="uploadHandler(file)"></p>
const Upload = {
  beforeMount(el, binding) {
          
      const accept = []
    
      // images
      if (binding.modifiers.jpg || binding.modifiers.images) accept.push(".jpg,.jpeg")
      if (binding.modifiers.png || binding.modifiers.images) accept.push(".png")
      if (binding.modifiers.gif || binding.modifiers.images) accept.push(".gif")
      
      // vector
      if (binding.modifiers.svg) accept.push(".svg")
      
      // video
      if (binding.modifiers.video) accept.push("video/*")
    
      // audio
      if (binding.modifiers.audio) accept.push("audio/*")
      
      // docs
      if (binding.modifiers.csv || binding.modifiers.docs) accept.push(".csv")
      if (binding.modifiers.txt || binding.modifiers.docs) accept.push(".txt")
      if (binding.modifiers.pdf || binding.modifiers.docs) accept.push(".pdf")
      if (binding.modifiers.doc || binding.modifiers.docs) accept.push(".doc")
      if (binding.modifiers.doc || binding.modifiers.docs) accept.push(".docx")
      if (binding.modifiers.xls || binding.modifiers.docs) accept.push(".xls") 
      if (binding.modifiers.xls || binding.modifiers.docs) accept.push(".xlsx")
      if (binding.modifiers.ppt || binding.modifiers.docs) accept.push(".ppt")
      if (binding.modifiers.pptx || binding.modifiers.docs) accept.push(".pptx")
    
      // archive
      if (binding.modifiers.zip  || binding.modifiers.archive) accept.push(".zip")
      if (binding.modifiers.rar  || binding.modifiers.archive) accept.push(".rar")
      if (binding.modifiers.gzip || binding.modifiers.archive) accept.push(".gzip")

      
      
      // input
      const id = Math.round(Math.random()*1000)
      const input = document.createElement('input')
      input.type = "file";
      input.id = id.toString()
      input.style.position = 'absolute'
      input.style.display = 'none'
      input.style.left = '-100vw'
      input.accept = accept.join(',')
      input.multiple = !binding.modifiers.one
      input.onchange = (e) => binding.value(e.target.files)
    
      // input append
      const body = document.createElement('p')//  document.getElementById('app')
      body.appendChild(input)
    
      // upload
      const handler = () => input.click()
      el.addEventListener('click', handler);
 }

}

export default Upload

/* 
https://stackoverflow.com/questions/71782998/vuejs3-how-to-register-a-custom-global-directive-in-a-separate-folder

const TrackDirective = {
    beforeMount: (el, binding) => {
        el.addEventListener('click', () => {
            console.info('tracking')
        })
    }
}
export default TrackDirective


import upload from "upload.js";
app.directive('upload', upload)


<template>
  <div v-track>
    click on this text
  </div>
</template>
*/