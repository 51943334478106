import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const SettingsStore = defineStore('settings', {
  state: () => ({ 
    
    // global
    dark      : useStorage('dark', true),    
    lang      : useStorage('lang', 'en'),
    update    : useStorage('update', 0),
    
    // local
    login: false,
    showError: false,
    error: {},
    status: undefined, //page status,  404, error, loading
    line: undefined, //line every screen bottom right
    savedPopup: undefined, //message right top (only via showSaved() )
    connection: false, 
    back: undefined, //for back color on custom pages

    // admin
    copytotal: undefined,
    copynew: undefined,
    copyactive: undefined,
    copyinactive: undefined,
    accounts: undefined,

    header:{
      show: true,
      color: undefined,
    },
    
  }),
  
  getters: {
      
  },
  actions: {

    resetPage(){
      this.login = false
      this.showError = false
      this.error = {}
      this.status = undefined
      this.line = undefined
      this.savedPopup = undefined
      this.connection = false
      this.back = undefined
      this.header.color = undefined
    },
    
    saved(){
      if (this.savedPopup) return
      this.savedPopup = true
      setTimeout(() => {this.savedPopup = false}, 2000);
    },

    noConnection(){
      if (this.connection) return
      this.connection = true    
      setTimeout(() => {this.connection = false}, 2000);      
    },

    err(id, ref, title, desc){
      this.error = {
        id:undefined, //connection
        ref: undefined,
        title:undefined,
        desc:undefined,
        solution:undefined,        
        code:undefined, //500
        data:undefined
      }
      return
    },
    setMode(v){
      this.dark = v
    }, 

    admin:{
      init(){
        return
      }
    },

  },
})

