<template>
  
    <div class="box">
      <router-link :to="to">            
      
        <h3 class="title"> <span :style="{color: accent || item.design.accent}">{{ $t('type.'+item.type) }}:</span>  {{ item.title }} </h3>
        
        <div class="row" style="margin: 0px 0 8px;">
          <div v-if="stars" class="row" style="margin-right: 10px; margin-top: 1px;">
            <svg v-for="(item, index) in stars" :key="index" width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0L8.5716 4.83688H13.6574L9.5429 7.82624L11.1145 12.6631L7 9.67376L2.8855 12.6631L4.4571 7.82624L0.342604 4.83688H5.4284L7 0Z" fill="#FFB800"/></svg>            
          </div>
          <p class="desc">{{ $t(`type.${item.type}`, item.lang) }}, 5 min ago</p>
        </div>
        
        <p class="line">{{ item.line }} </p>
        <img v-if="item.logo" :src="item.logo" class="logo" :class="{multiply: !db.settings.dark}">
      
    </router-link>
    <br>
    <div class="row ch inputShadow" v-for="(file, index) in item.files" :key="index"  style="background: var(--inputBack);text-align: center; width: 100%; border-radius: 15px;  padding: 15px 25px; margin: 4px 0px;">
        <img style="height: 44px;" :src="`/images/icons/file/${file.ext || 'TXT'}.svg`" onerror="this.onerror=null;this.src='/images/icons/file/TXT.svg';">
        <div class="row between ch">
          <div class="column textleft" style="margin: 0 20px;">
            <p class="medium" style="word-break: break-all;line-height: 120%">{{ file.name }} </p>
            <p class="desc" style="font-size: 11px;">{{ humanize.filesize(file.size, decimals = 0) }} </p>
          </div>
        </div>
        <!-- delete -->
        <!-- <div :title="$t('deleteFile')" v-if="item.files.length > 1" class="pointer click" style="position: absolute; right: 20px;" @click="deleteFile(file.id)">
          <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1816_114)"><path d="M3.516 20.484C2.36988 19.377 1.45569 18.0529 0.826781 16.5889C0.197873 15.1248 -0.133162 13.5502 -0.147008 11.9568C-0.160854 10.3635 0.142767 8.78331 0.746137 7.30855C1.34951 5.83379 2.24055 4.49397 3.36726 3.36726C4.49397 2.24055 5.83379 1.34951 7.30855 0.746137C8.78331 0.142767 10.3635 -0.160854 11.9568 -0.147008C13.5502 -0.133162 15.1248 0.197873 16.5889 0.826781C18.0529 1.45569 19.377 2.36988 20.484 3.516C22.6699 5.77922 23.8794 8.81045 23.8521 11.9568C23.8247 15.1032 22.5627 18.1129 20.3378 20.3378C18.1129 22.5627 15.1032 23.8247 11.9568 23.8521C8.81045 23.8794 5.77922 22.6699 3.516 20.484ZM13.68 12L17.076 8.604L15.384 6.912L12 10.308L8.604 6.912L6.912 8.604L10.308 12L6.912 15.396L8.604 17.088L12 13.692L15.396 17.088L17.088 15.396L13.692 12H13.68Z" fill="var(--g3)"/></g><defs><clipPath id="clip0_1816_114"><rect width="24" height="24" fill="var(--g3)"/></clipPath></defs></svg>
        </div> -->
      </div>  
      <br>

      <slot></slot>
    </div>

</template>
<script>
export default {
  data() {return {}},
  props:{
    item:{},
    stars: Number,
    link:String,
    accent:String,
  },  
  computed:{
    time(){
      let unix = this.item.created
      return this.tools.ago(unix, this.item.lang)
    },
    to(){
      return this.tools.copylink(this.item.id, this.item.title, this.$t('type.'+this.item.type, this.item.lang))
    }
    
  }
};
</script>
<style scoped>

.box{  
  margin: 20px 30px;   
  min-width: 400px;
  max-width: 290px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.title{
  color: var(--text); 
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-size: 44px;
  line-height: 130%;
  line-height: 110%;
  text-align: left;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
}
.desc{
  font-weight: 400; 
  font-size: 12px; 
  line-height: 11px;
  margin: 2px 0 10px;
  text-align: left;
  color: var(--g3);
  
}
.line{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.03em;
  color: var(--g4);
  text-align: left;
}
.logo{
  
  margin: 10px 0 4px;
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
  text-align: left;
  display: block;
  
  max-width:100px;
  max-height:30px;
  width: auto;
  height: auto;
}
</style>