import api from '@/ts/api';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';


export const ProfileStore = defineStore('profile', {
  state: () => ({ 
    me : useStorage('copy.profile', {}),
    copylist : useStorage('copy.list', []), //my copies
    downloadlist : useStorage('copy.download', []),
    onbording : useStorage('onbording', false),
  }),
  
  getters: {
    id: (state) => state.me.id,
    admin: (state) => state.me.meta ? state.me.meta.admin : false,
    adminmode: (state) => state.me.adminmode,
    
  },
  
  actions: {

     async tickets(){
      return {resp: [
        {id:'1', mine: true, text:`Although a Go module is not exactly the same as a package, for the purposes of this article we use the terms module, package and dependency interchangeably, always referring to a Go module as a collection of packages with go.mod and go.sum files.`},
        {id:'2', text:`Updating external packages is an important part of maintaining any application`},
        {id:'3', mine: true, text:`In Go, to do an upgrade of all packages imported by Go files in the given root directory and all its subdirectories, use the following go get command`},
        {id:'4', mine: true, text:`Although a Go module is not exactly the same as a package, for the purposes of this article we use the terms module, package and dependency interchangeably, always referring to a Go module as a collection of packages with go.mod and go.sum files.`},
        {id:'5', text:`Updating external packages is an important part of maintaining any application`},
        {id:'6', mine: true, text:`In Go, to do an upgrade of all packages imported by Go files in the given root directory and all its subdirectories, use the following go get command`},
        {id:'7', mine: true, text:`Although a Go module is not exactly the same as a package, for the purposes of this article we use the terms module, package and dependency interchangeably, always referring to a Go module as a collection of packages with go.mod and go.sum files.`},
        {id:'8', text:`Updating external packages is an important part of maintaining any application`},
        {id:'9', mine: true, text:`In Go, to do an upgrade of all packages imported by Go files in the given root directory and all its subdirectories, use the following go get command`},
        {id:'10', mine: true, text:`Although a Go module is not exactly the same as a package, for the purposes of this article we use the terms module, package and dependency interchangeably, always referring to a Go module as a collection of packages with go.mod and go.sum files.`},
        {id:'11', text:`Updating external packages is an important part of maintaining any application`},
        {id:'12', mine: true, text:`In Go, to do an upgrade of all packages imported by Go files in the given root directory and all its subdirectories, use the following go get command`},
      ]}
     }, 

      // download copy
      async download(copy, filename, values){        
        console.log('profile/download', copy, values, filename)        
        // update profile
        if (Object.keys(values).length) {
          let resProfile = await api.account.updates(values)
          if (!resProfile) return {error:{id: 'connection'}}
          if (resProfile.error) return resProfile
          if (!resProfile.resp) return {error:{id: 'try'}}        
          this.me = resProfile.resp
        } else {
          console.log('no need values')
        }
        
        // download
        let res = await api.download(copy, filename)

        // fail
        if (res && res.error) return res

        // success
        if (this.downloaded(copy)) return
        this.downloadlist.push(copy)
      },

      // downloaded
      downloaded(copy) {
        return this.downloadlist.indexOf(copy) > -1
      },
      
      
      //login
      async login(provider, key, token){
        let res;
        switch (provider) {
          case 'google' : res = await api.login.google(token); break;
          case 'apple'  : res = await api.login.apple(token); break;
          case 'email'  : res = await api.login.email(key,token); break;
          default: return {error:{id:'provider'}}
        }
        if (!res) return {error:{id:'internal'}}
        if (res.error) return res.error
        this.me = res.resp.profile
        return {}
      },


      // актуальный профиль
      async refresh(){
        const res = await api.account.profile()
        console.log('store/accounts/me', res)
        if (res.error) return
        if (!res.resp || !res.resp.profile) return
        this.me = res.resp.profile
    },

    // обновление профиля
      async update(k, v){      
        console.log('profile/update', {k, v})
        
        console.log('update v', this.me[k], v)
        // same
        if (this.me[k] == v) {
          console.log(k,'already has')
          return false
        }

        // update        
        const res = await api.account.update(k, v)
        if (res.error) return res        
        this.me[k] = v
        return true
    },

      // выход из текущего аккаунта и переключение на следующий
      async logout(){

        const res = await api.account.logout()
        if (!res){
          console.error("no api result")
          alert('Something wrong with connection. Please try again later.')
          return
        }
        if (res.error) {
          console.error(res.error)
          alert('Something wrong with connection. Please try again later.')
          return
        }

          // удаляем куки
          console.log("logout clear")
          this.clear()
          
          window.location = '/'
          return
      },

      // clear
      clear(){
        
        this.me = {}
        this.copylist = []
        this.downloadlist = []
        localStorage.removeItem('copy.profile')
        localStorage.removeItem('copy.list')
        localStorage.removeItem('copy.download')
        localStorage.removeItem('update')          
      },
     
  },
})

