<template>
  <div class="loader" :style="{width: width, border: borders, borderRightColor:color}"></div>
</template>
<script>
export default {
  data() {return {};},
  props:{
    color:{type: String, default:'#25b09b'}, 
    width:{type:String, default:'50px'},
    borders:{type:String, default:'4px solid #0000'}
  },  
};
</script>
<style scoped>

/* 

    <Loader width="100px" color="#25b09b" borders="4px solid #0000"/>
    
 */
.loader {    
    aspect-ratio: 1;
    display: grid;    
    border-radius: 50%;
    /* border-right-color: #25b09b; */
    animation: l15 1s infinite linear;
  }
  .loader::before,
  .loader::after {    
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
  }
  .loader::after {
    margin: 8px;
    animation-duration: 3s;
  }
  @keyframes l15{ 
    100%{transform: rotate(1turn)}
  }
</style>