<template>
  
    <div 
      ref="dropbox"
      @paste="onPaste"
      class="uploadArea"
      :class="{dragging: dragging}"
      @dragover="dragover"
      @dragleave="dragleave"      
      style="position: relative;">
      <p v-if="text" class="uploadText">{{ text }}</p>
     <div class="centerBox"><slot></slot></div> 
    </div>
  
  <!-- 
      <Drop @files="uploadMultiFiles"></Drop>
   -->
</template>

<script>
import { useDropZone } from '@vueuse/core';
import { ref } from 'vue';
export default {
  mounted() {
    const dropZoneRef = ref(this.$refs.dropbox)
    const { isOverDropZone } = useDropZone(dropZoneRef, this.drop)
  },
  data() {
    return {
      dragging: false,      
    };
  },
  props:{
    text:String,
    style:{type:String, default:''},
    draggingStyle:{type:String, default:''},
  },
  
  methods: {
    
    dragover(e) {
      e.preventDefault();
      this.dragging = true;
    },
    
    dragleave(e) {
      e.preventDefault();
      this.dragging = false;
    },

    drop(files) {
      this.$emit('files', files)
      this.dragging = false;            
    }, 
},
};
</script>
<style scope>

.uploadArea{
  cursor: pointer;
  position: relative;
  /* min-height: 200px; */
  background: none;
  border: 6px dashed var(--g4);
  border-radius: 15px;
  transition: all 0.2s ease;
}

.uploadArea:hover{
  background: var(--g1);
  /* border: 1px solid var(--g2); */
}

.dragging{
  border: 1px dashed var(--g4);
  background: var(--g1);
}

.uploadText{
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: var(--g4);

  top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
  .centerBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>