<template>

<h1>Accounts</h1>
<br>

<!-- empty -->
<div v-if="!items.length" class="relative column backsub center shadow w1000 borders" style="border: none; padding: 50px 50px;margin-bottom: 50px;">
    <gap medium/>
      <p class="desc w300 mb4">Nothing</p>
      <gap medium/>
</div>

<!-- has own copies -->
<div v-else class="column">
    <table class="w1200 table">
      <tr v-for="(item, index) in items" :key="index">
        <td class="cc">👨🏼‍💼</td>
        <td class="cc">{{ item.id }}</td>
        <td class="ttt">{{ tools.ago(item.created) }}</td>
        <td class="cc">{{ item.profile.provider }}</td>
        <td class="tt">{{ item.profile.email }}</td>                
        <td class="nn">{{ item.copy || 0 }}</td>                
        <td class="nn">{{ item.files || 0 }}</td>                
        <!-- <td><button type="button">Delete</button></td> -->

      </tr>
    </table>
    
  </div>
    

</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  
  mounted() {
     this.getlist()
    
  },
  data() {
    return {

      items:[],
      
    }
  },
  methods: {

    async getlist(){      
      let res = await this.api.admin.users.list()
      console.log(res)
      if (res.error) {
        console.log(res.error)
        return
      }
      this.items = res.resp 
    },
      
  },
});

</script>

<style scoped>
.activeCopy{
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background: var(--success);
}

  
</style>

