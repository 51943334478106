<template>

    <div class="column center shadow w1000 backsub borders" style="padding: 50px 30px;">
      <h2>Done</h2>
      <p class="desc w400">{{ $t('desc.account.done') }}</p>
      <br>
      
      <!-- filled -->
      <div class="w500 column left" v-for="(item, index) in fields" :key="index">
        <p>{{$t('fields.'+item.id)}}</p>        
        <input  class="input w500" @keydown.enter="update(item.id, $event.target.value)" @blur="update(item.id, $event.target.value)" :value="item.value" type="text" :placeholder="$t('examples.fields.'+item.id)" :class="{filled: db.profile.me[item.id]}">
        <br>
      </div> 
      <gap></gap>

      <h2>Need to fill</h2>
      <p class="desc w450">{{ $t('desc.account.need') }}</p>
      <br>

      <!-- empty -->
      <div class="w500 column left" v-for="(item, index) in emptyFields" :key="index">
        <p>{{$t('fields.'+item.id)}}</p>        
        <input :class="{filled: db.profile.me[item.id]}" class="input w500" @keydown.enter="update(item.id, $event.target.value)" @blur="update(item.id, $event.target.value)" :value="item.value" type="text" :placeholder="$t('examples.fields.'+item.id)">
        <br>
      </div> 
      
      
    </div>
 

</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({  
  async mounted() {
    await this.db.profile.refresh()
    
  },
  data() {
    return {
      
    }
  },
  computed: {
    
    fields(){      
        let list = []
        for (const field of this.db.dict.field){
          if (field == 'email') continue
          let item = this.db.dict.fields[field]          
          item.value = this.db.profile.me[field]
          if (!item.value) continue
          list.push(item)
        }
        // list.sort((a, b) => a.id.localeCompare(b.id))        
        return list
    },

    emptyFields(){      
        let list = []
        for (const field of this.db.dict.field){
          if (field == 'email') continue
          let item = this.db.dict.fields[field]          
          item.value = this.db.profile.me[field]
          if (item.value) continue
          list.push(item)
        }
        // list.sort((a,b) => {
        //   let aa = !this.db.profile.me[a.id]
        //   let bb = !this.db.profile.me[b.id]
        //   return aa - bb
        // });
        return list
    },


  },
  methods: {   

    async update(k,v){    
       if (this.db.profile.me[k] == v) return
      console.log('profile page update', k, v)
      let res = await this.db.profile.update(k,v)
      console.log('updating result',res)
      if (!res) return
      if (res.error){
        if (res.error.code == 500){
          console.error("connection")
          this.db.settings.noConnection()        
        }
        return
      }
      this.db.settings.saved()
      
      
    },
    

  },
});

</script>

<style scoped>

</style>

