import { SettingsStore } from '@/ts/store/settings';
import { mande } from 'mande';
import pinia from './store/pinia';
const apipath = process.env.NODE_ENV == 'development' ? 'http://localhost:9000' : '/api'
const request = mande(process.env.apihost || apipath)

// const store = useMainStore(pinia)
const settings = SettingsStore(pinia)

// send
const send = async function(name, req){
  req = req || {}
  let lang = settings.lang
  let update = settings.update
  console.log('req', {name, update, lang, req})
  try {    
    let resp = await request.post({name, update, lang, req})
    settings.update = Date.now()
    console.log('resp', {name, resp})
    if (!resp) resp = {}
    return resp

  } catch (error) {
    console.error(error.body)
    return {error:{id:'connection', code: 500}}    
  }

  // let resp = await request.post({name, update, lang, req})
  
  
};


// api
export default {
  // support 
  async support(message, tg) {return await send('support.message',{message,tg})},

  
  // accounts
  account:{     
      async profile() {return await send('account.me')},
      async update(k,v) { return await send('account.update',{k,v})}, 
      async updates(map) { return await send('account.updates',{profile: map})},
      async logout() {return await send('account.logout')},
  },

  // login
  login:{
    async email(key, token) {return await send('account.login',{provider:'email', key, token})},
    async google(token) {return await send('account.login',{provider:'google',token})},
    async apple(token) {return await send('account.login',{provider:'apple', token})},
  },
  
  // copy
  session:{ 
    async new() {return await send('session/new',{})},    
  },

  // files
  files:{ 
    async delete(copy,id) {return await send('files.delete',{copy,id})},    
  },
  // files
  hooks:{ 
    async airtableDatabases(token) {return await send('hooks.airtable.databases',{token})},    
  },


  // team
  admin:{ 
    users:{
      async list(limit, offset, q) {return await send('admin.users',{limit,offset,q})},
      async search(limit, offset, q) {return await send('admin.search.users',{limit,offset,q})},
    },
    copy:{
      async new() {return await send('admin.copy.new',{})},
      async list(limit, offset) {return await send('admin.copy.list',{limit, offset})},
      async search(limit, offset, q) {return await send('admin.search.copy',{limit,offset,q})},
      async feat(id) {return await send('admin.search.copy',{id})},
      async valid(id) {return await send('admin.copy.valid',{id})},
      async active(id, active) {return await send('admin.copy.active',{id, active})},
      
    },
    team:{
      async add(id) {return await send('admin.team.add',{id})},
      async remove(id) {return await send('admin.team.remove',{id})},
    },

    async stats() {return await send('admin.stats',{})},          
   
  },

// copy id
async uploadFile(id, name, body){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/file?name=${name}&&id=${id}`;
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(body);
        rs.send(uint8);
      });
    },

    async uploadImage(id, body){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/image?id=${id}`;
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(body);
        rs.send(uint8);
      });
    },

    async uploadLogo(id, body){
      return new Promise(function (resolve, reject) {
        const rs = new XMLHttpRequest();
        let host = `${apipath}/upload/logo?id=${id}`;
        rs.open('post', host, true);
        rs.onload = function () {
          if (rs.status != 200)
            reject({ error: { id: 'try' } });
          if (!rs.response) resolve({});
          const resp = JSON.parse(rs.response);
          resolve(resp);
        };
        const uint8 = new Uint8Array(body);
        rs.send(uint8);
      });
    },


    //download copy file
    async download(id, filename) {
      console.log("api/download")
      let name = 'copy.download'
      let lang = settings.lang
      const res = await fetch(apipath, {method: 'POST', body: JSON.stringify({name,lang, req:{id, filename}})});
      console.log(res)      
      let data = await res.blob()
      // console.log("data", data)

      // error
      if (data.type == 'text/plain'){        
        let text = await data.text()
        // console.log("data text",text)
        let json = JSON.parse(text)
        // console.log("json",json)
        return json
      }
      
      // ok
      await this.downloadBlob(data, filename)
      return {resp:{}}
  },

    //download copy file
    async downloadLeads(id, format, filename) {
      console.log("api/leads")
      let name = 'copy.leads'
      let lang = settings.lang
      const res = await fetch(apipath, {method: 'POST', body: JSON.stringify({name,lang, req:{id, format, filename}})});
      console.log(res)      
      let data = await res.blob()
      // console.log("data", data)

      // error
      if (data.type == 'text/plain'){        
        let text = await data.text()
        // console.log("data text",text)
        let json = JSON.parse(text)
        // console.log("json",json)
        return json
      }
      
      // ok
      await this.downloadBlob(data, filename)
      return {resp:{}}
  },

  async downloadBlob(blob, name) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) return window.navigator.msSaveOrOpenBlob(blob);

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download = name;

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
          new MouseEvent('click', { 
              bubbles: true, 
              cancelable: true, 
              view: window 
          })
      );

      setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
      }, 100);
  },


}





