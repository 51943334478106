<template>
    
    <Transition name="bounce">    
        <div v-if="modelValue" class="main">
        <div ref="target" class="pop center" style="position: fixed">            
            <svg style="position: absolute; top: 10px;left: 10px; cursor: default;" @click="close()" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle class="pointer" cx="6" cy="6" r="6" fill="var(--g1)"/></svg>
            <div class="column center">  
                <div class="column center" style="margin: 10px 0 20px;">
                    <div v-if="$slots.icon" class="icon"><slot name="icon"></slot></div>                    
                    <p v-if="$slots.title" class="title"><slot name="title"></slot></p>
                    <p v-if="$slots.line" class="line"><slot name="line"></slot></p>
                    <p v-if="$slots.desc" class="desc w300"><slot name="desc"></slot></p>                
                </div>              
                <div class="body"><slot></slot></div>            
            </div>            
               
        </div>  
    </div>
    </Transition>
    
<!--         
    
    <Popup v-model="db.settings.login">
        <template #icon><svg/></svg></template>
        <template #title>{{$t('title.welcome')}}</template>
        <template #line>{{$t('line.welcome')}}</template>
        <template #desc>{{$t('desc.welcome') }}</template>  
        <Login @done="db.settings.login = false"/>      
    </Popup>        

-->
     
</template>
<script>
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
export default {
    setup(props, { emit }) {
        const target = ref(null)
        onClickOutside(target, (event) => emit('update:modelValue', false))
        return { target}
    },
    data: function () {
        return {}},
    props:{
        modelValue: Boolean,
    },
    methods: {
        close(){
            this.$emit('update:modelValue', false);
        },
    },

    filters: {
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    }
  },
}
</script>

<style scoped>

.main{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    position: fixed; 
    z-index: 1000; 
    width: 100vw; 
    height: 100vh; 
    top: 0;
    left: 0;
}
   .pop {
       display: flex;
       flex-direction: column;
       justify-content: flex-start;
       align-items: center;
       background: var(--popupBack);
       padding: 30px 60px 30px;
        border-radius: var(--popupBorder);    
        position: absolute;  
        z-index: 1000;        
        /* box-shadow: 0px 100px 426px rgba(0, 0, 0, 0.18), 0px 46.233px 196.953px rgba(0, 0, 0, 0.0784783), 0px 26.4535px 112.692px rgba(0, 0, 0, 0.0379435), 0px 16.0571px 68.4031px rgba(0, 0, 0, 0.0222537), 0px 9.67509px 41.2159px rgba(0, 0, 0, 0.0197508), 0px 5.38772px 22.9517px rgba(0, 0, 0, 0.0237333), 0px 2.31722px 9.87136px rgba(0, 0, 0, 0.0270284); */
        box-shadow: var(--popupShadow);
        max-height: 90vh;
        overflow-y: scroll;
   }

   .icon{
    margin: 0px 0 10px;
   }
   .title{
        font-family: var(--titleFonts);
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 60%;
        text-align: center;
        letter-spacing: -0.02em;
        color: var(--text);        
        margin-bottom: 16px;
   }

   .line{
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        text-align: center;
        color: var(--g5);
        margin-top: 0px;
        
   }

   .desc{
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 140%;
        text-align: center;
        color: var(--g5);
        margin-top: 4px;
        letter-spacing: 0.02em;
   }
   
   .headspace{
    display: flex;
       flex-direction: column;
       justify-content: center;
    width:100%; 
    padding: 10px 0;
    user-select: none;
   }
   .nopadding{
    padding:0
   }
   .wrapper{
       position: relative;
       display: flex;
       flex-direction: column;
       /* justify-content: space-between; */
       align-items: flex-start;
       height: 100%;
       width: 100%;
   }


    .body{
        position: relative;
       display: flex;
       flex-direction: column;
       width: 100%;
       padding: 0px 0px;
   }

    .close{
      cursor: default;
      transition: all 0.1s ease;
    }
    
    .close:hover{
      fill-opacity: 0.16;
    }

</style>