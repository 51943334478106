<template>
    <div class="w800 column left">                
      <div class="row" style="margin: 10px 0 0px;">
          <Switch v-if="tools.has(copy.fields,'country')" :title="$t('desc.addCountryList')" v-model="country">{{$t('switch.addCountryList')}}</Switch>                    
      </div>      
      <highlightjs style="text-align: left; font-size: 13px; border-radius: 15px;" language="xml" :code="htmlform"/>
      <div class="row center">
        <p @click="copyCode()" class="button compact white">{{ title }}</p>
      </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  mounted() {
  //  
  },
  props:{
    copy:{}
  },
 
  data() {
    return {
      title: this.$t('buttons.copyCode'),
      country: false,  
    
    }
  },
  computed: {
    countryOptions(){
      let list = this.db.dict.country
      let p = []
      list.forEach(c => {
          p.push(`<option value="${c}">${this.$t('country.'+c)}</option>`)
      });
      return p.join("")
    },

    htmlform(){      
      let c = this.copy
      let form = []
      let action = this.dev  ? 'http://127.0.0.1:9000/download' : 'https://meetcopy.com/download'
      form.push(`<form action="${action}">`)
      console.log("html fomr fields", c.fields)

      /* company: {id: 'company', type:'input', title: 'fields.company'},
        employees: {id: 'employees', type:'select', translate:'employees',options: this.employees, title: 'employees.employees'}, */
      for (const name of c.fields){
        console.log("html check", name)
        let field = this.db.dict.fields[name]
        console.log("html field", field)
        if (!field) continue     
        
        // email
        if (name == 'email'){
          if (this.dev){
            form.push(`<input type="email" id="${name}" name="${name}" :value="\`rand${Math.floor(Math.random() * 5000000)}@gmail.com\`" placeholder="${this.$t('fields.'+name)}*"><br>`); 
          } else{
            form.push(`<input type="email" id="${name}" name="${name}" placeholder="${this.$t('fields.'+name)}*"><br>`); 
          }
            
            continue
        }

        if (name == 'country'){
          if (this.country){
              form.push(`<select name="country">${this.countryOptions}</select><br>`)
            } else{
              form.push(`<input type="text" id="${name}" name="${name}" placeholder="${this.$t('fields.'+name)}*"><br>`)
            }     
        }

        // text
        if (field.type == 'input'){           
            form.push(`<input type="text" id="${name}" name="${name}" placeholder="${this.$t('fields.'+name)}*"><br>`); 
            continue                    
        }

       
      }

     /*  c.fields.forEach(r => {
        
        console.log("html check", r)
        switch (r) {
          case 'email': 
              form.push(`<input type="email" id="email" name="email" placeholder="${this.$t('fields.'+r)}"><br>`); break
          case 'name', 'family','job', 'company','link','linkedin','city','state','zip': 
              console.log("html fomr name!")
              form.push(`<input type="text" id="${r}" name="${r}" placeholder="${this.$t('fields.'+r)}"><br>`); break
          case 'link', 'linkedin': 
              form.push(`<input type="link" id="${r}" name="${r}" placeholder="${this.$t('fields.'+r)}"><br>`); break
          case 'phone': 
              form.push(`<input type="phone" id="${r}" name="${r}" placeholder="${this.$t('fields.'+r)}...+15793927979573"><br>`); break
          case 'country':
            if (this.country){
              form.push(`<select name="country">${this.countryOptions}</select><br>`)
            } else{
              form.push(`<input type="text" id="${r}" name="${r}" placeholder="${this.$t('fields.'+r)}"><br>`)
            }             
             break
          default: 
          console.log("default", r)
          break
        }
      }); */
      // form.push(` <input hidden name="id" value="${this.copy.id}">`)
      form.push(`<input hidden name="token" value="${this.copy.sid}">`)
      form.push(`<button type="submit" onclick="javascript:register()">${this.$t('getFreeCopy')}</button>`)
      form.push(`</form>`)      
      return form.join("\n")
    },
   
  },
  methods: {
    async copyCode(){
      let text = this.htmlform
      await this.clipboard(text)
      this.title = this.$t('buttons.codeCopied')
      setTimeout(() => {
        this.title = this.$t('buttons.copyCode')
      }, 300);
    },
  },
  
});
</script>
<style scoped>

.designOptionsInputWidth{
  width: 187px;
  height: 50px;
}
.previewImage{
    position: relative; 
    height: 400px;   
    /* filter: drop-shadow(20px 24px 34px rgba(0, 0, 0, 0.001)); */
}

.previewContent{
  position: relative; 
}

.previewShadow{
  width: 100%; height: 100%;
}

.previewRadius{
  border-radius: 30px;
}
.progress{
  user-select: none;
  opacity: .3;
  filter: grayscale(1);
}

.backHover{
  background: none;
  color: var(--g6);
  border: 6px dashed var(--g4);
  transition: all 0.2s ease;
}

.backHover.active{
  background: none;
  color: var(--g6);
  border: 1px solid var(--g1);
  
}

.backHover:hover{
  background: var(--primary);
  color: var(--g6);
}
.subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.0em;
  color: var(--g4);
  margin: 10px 0;
  /* margin-left: -5px; */

}

.tabs {
  margin: 0 10px;
}

.texttab{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: left;
  width: 300px;
  height: 270px;
  padding: 30px 30px;
  background: var(--g05);
  /* border: 1px solid var(--g1); */
  margin: 10px;
}
/* 
.texttab:first-child{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.texttab:last-child{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
} */

.texttab.active{
  background: var(--primary);
}

.texttabTitle{
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 25px;
}
.texttabDesc{
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 140%;
letter-spacing: 0.02em;
color: var(--g6);
margin-top: 8px;
}

.texttabPrice{
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 140%;
letter-spacing: 0.0em;
color: var(--g8);
margin-top: 8px;
}

.texttabSpan{
padding: 1px 5px;
background: rgba(255, 0, 230, 0.1);
border: 2px solid #000000;
box-shadow: 1px 2px 0px #000000;
border-radius: 190px;
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 123%;
color: #000000;
margin: 0 0 2px;
}

.grayBackHover:hover{
  background: var(--g05);
}

.lineCheckBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: 6px 6px 6px 6px; 
  border-bottom: 1px solid var(--g05);
  background: var(--inputBack);
  cursor: pointer;
  transition: all 0.2s ease;
}

.lineCheckBox:first-child{
  border-radius: 15px 15px 0 0;
}
.lineCheckBox:last-child{
  border-radius: 0 0 15px 15px;
}

.lineCheckBox.active, .lineCheckBox.active:hover{
  background: var(--primary);
}

.lineCheckBox.disable, .lineCheckBox.disable:hover{
  opacity: .8;
}

.lineCheckBox:hover{
  background: var(--g01);
}

.lineCheckBoxTitle{
  font-size: 18px; 
  margin-left: 10px;
  color: var(--g4);
}

.lineCheckBoxTitle.active{
  font-weight: 700;
  color: var(--text);
}

.lineCheckBoxDesc{
  font-size: 14px; 
  margin: 0 10px;
  color: var(--g3);
}



</style>