<template>

    <!-- button -->
    <div class="column ch center" style="height: 20vh;">             
        <!-- <div v-if="progress"><Loader :color="design.accent"/> </div>      
        <p class="button" v-if="!show" :style="{background: design.button.background, border: design.custom ? `2px solid ${design.button.background}`: '', color: design.button.title}"  @click="download()">{{ $t('getFreeCopy') }} </p>
    
        <div v-if="db.profile.me.id">
          <div v-if="fieldsDone" class="center row wrap w280" style="margin-top: 2px;">            
              <p v-if="!mine" class="text desc" :style="{color: design.desc}" style="font-size: 12px; opacity: .4;"> {{ $t('yourDataFiled') }} {{ fieldsDone }} <a href="/profile/edit">edit</a> </p>
              <p v-else class="text desc w250" :style="{color: design.desc}" style="font-size: 12px;opacity: .4;"> {{ $t('thisIsYourCopy') }}   </p>
          </div>
        </div>
        <div v-else>
          <div class="center row wrap w280" style="margin-top: 2px;">            
              <p class="text desc"  :style="{color: design.desc}" style="font-size: 12px; opacity: .4;"> {{ $t('publicDownloadDesc') }} </p>                    
          </div>
        </div> -->
    </div>

    <!-- form -->
    <Popup v-model="show">
        <template #icon><svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M53.625 14.0625C47.0625 14.0625 41.2812 17.3437 38 22.5C34.7188 17.3437 28.9375 14.0625 22.375 14.0625C12.0625 14.0625 3.625 22.5 3.625 32.8125C3.625 51.4062 38 70.3125 38 70.3125C38 70.3125 72.375 51.5625 72.375 32.8125C72.375 22.5 63.9375 14.0625 53.625 14.0625Z" :fill="design.accent || '#F44336'"/></svg></template>
        <template #title>{{$t('download')}}</template>
        <template #line>{{$t('line.hooks.email')}}</template>
        <template #desc>{{ $t('copy.popupDesc') }}</template>          
        <div class="column w400 center">
            
          <!-- fields -->
      <div v-if="fields().length" class="w400 mb4" style="padding: 0px 0px;  border-radius: 0px;">
        <div class="column left textleft" v-for="(field, index) in fields()" :key="index">
            <div class="column relative" v-if="field.type == 'input'">
              <!-- <p class="formInputTitle require" >{{ $t('fields.'+field.id)}} </p> -->
              <input class="input inputShadow" type="text" :style="{zIndex:1+index}" v-model="values[field.id]" :placeholder="field.placeholder+'*'">
            </div>
            <div class="column relative" v-if="field.type == 'select'" :style="{zIndex:1+index}" >
              <!-- <p class="formInputTitle require">{{ $t('fields.'+field.id)}} </p> -->
              <br>
              <h4 class="require mb4">{{ $t('fields.'+field.id)}}</h4>
              <div class="row wrap nospace">                  
                <p class="tag" v-for="(option, index) in field.options" :key="index" @mousedown="values[field.id] = option" :class="{active: option == values[field.id]}">{{ $t(`${field.translate}.${option}`)}} </p>
              </div>
            </div>            
        </div>
      </div>  

            <br>
            <p class="button compact white" @click="send">{{$t('download')}}</p>                
        </div>              
    </Popup>

    
    
</template>

<script>
import { humanize } from 'humanize';
import { defineComponent } from 'vue';
export default defineComponent({
  props:{
    copy:{}
  },
  async mounted() {    
    // this.values.name = this.db.profile.me.name
  },
 
  data() {
    return {
      owner:{
        countryList: false,
        hooks:[]
      },
      saved: false,
      showtypes: false,
      showtargets: false,
      login: false,
      progress: false,
      notfound:false,
      show:false,
      values:{},
      mine: false,
      paid: false, //already downloaded
      // copy:{
      //   id: undefined,
      //   title: undefined,
      //   line: undefined,
      //   text: undefined,
      //   tags: undefined, //t,y,u...
      //   type: undefined,
      //   medium: 'files',
      //   fields:['email'],
      //   targets:[],
      //   category: undefined,
      //   lang: 'en',        
      //   company: undefined,
      //   product: undefined,
      //   link: undefined,
      //   image: undefined,
      //   logo: undefined,
      //   design:{          
      //     background:{},
      //     button:{},           
      //   }
      // },
      time: undefined,
      logo:{width: undefined, height: undefined, box: undefined, vertical: undefined},
      humanize: humanize,
    }
  },
  computed: {

    
    design(){
        // custom
        if (this.copy.design.custom) return this.copy.design
      
        // scheme
        let defaultScheme = this.copy.design.scheme || 'gray'
        let mode = this.db.settings.dark ? 'dark' : 'light'
        let fontColor = this.db.settings.dark ? 'white' : 'black'
        let buttonTitle = 'black'
        let initColor = this.db.dict.colors[mode][defaultScheme]

        return {
          scheme: defaultScheme,
          background:{
            image: undefined,
            color: initColor.back,
          },
          accent: initColor.accent,
          button:{
            background: initColor.button,
            title: buttonTitle,
          },           
          title: fontColor,
          desc: fontColor,
          link:'#007DF0',          
          multiply: !this.db.settings.dark, //logo
          shadow: '.6'
        }
     },
    
    normLink(){
      if (!this.copy.link) return undefined
      if (this.copy.link.startsWith('http')) return this.copy.link
      return 'https://'+ this.copy.link
    },


    copydate(){
      if (!this.copy.created) return ''
      return this.tools.unixdate(this.copy.created)
    },

    fieldsDone(){
      var list = this.values
      var res = []
      for (var key in list){
        res.push(list[key])
      }
      console.log(res)
      return res.join(", ")
    },
    
  },
  methods: {  


    fields(){
      let p = this.db.profile.me
      let f = this.copy.fields
      var list = []
      for (let i = 0; i < f.length; i++) {
        const field = f[i];
        if (p[field]) {
          this.values[field] = p[field]
          continue
        }

        switch (field) {
          // case 'email':     list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'name':      list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'family':    list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'job':       list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'company':   list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break          
          case 'size':      list.push({id: field, type:'select',translate:'size',options:['solo','nano','small','medium','big','large','top'], placeholder: this.$t(`fields.${field}`)}); break          
          case 'link':      list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'linkdin':   list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'country':   list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'state':     list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'city':      list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'zip':       list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          case 'phone':     list.push({id: field, type:'input', placeholder: this.$t(`fields.${field}`)}); break
          // "email", "name", "family", "job", "company", "size", "link", "linkdin", "country", "state", "city", "zip", "phone"
        }
      }
      return list
    },

    async download(){

      if (!this.db.profile.me.id){
        this.login = true
        console.log("public")
        return
      }

      let f = this.fields()
      
      // need fill
      if (f.length) {
        this.show = true
        return
      }

      this.progress = true

      //done
      await this.send()   
      this.progress = false 
    },

    async send(){
      console.log('send...',this.copy.id, this.values)
      let name = this.tools.copyname(this.copy.title, this.copy.type)
      let res = await this.api.download(this.copy.id, this.values, name)
      console.log('download resp', res)
      if (res && res.error){
        alert(`Something wrong. Please try again later. Error: ${res.error.id} - ${res.error.c}`)
      }
      
    },
    
    
  },
  
});
</script>
<style scoped>

.progress{
  user-select: none;
  opacity: .3;
  filter: grayscale(1);
}

.backHover{
  background: none;
  color: var(--g6);
  border: 6px dashed var(--g4);
  transition: all 0.2s ease;
}

.backHover.active{
  background: none;
  color: var(--g6);
  border: 1px solid var(--g1);
  
}

.backHover:hover{
  background: var(--primary);
  color: var(--g6);
}
.subtitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.0em;
  color: var(--g4);
  margin: 10px 0;
  /* margin-left: -5px; */

}

.tabs {
  margin: 0 10px;
}

.texttab{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: left;
  width: 300px;
  height: 270px;
  padding: 30px 30px;
  background: var(--g05);
  /* border: 1px solid var(--g1); */
  margin: 10px;
}
/* 
.texttab:first-child{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.texttab:last-child{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
} */

.texttab.active{
  background: var(--primary);
}

.texttabTitle{
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 25px;
}
.texttabDesc{
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 140%;
letter-spacing: 0.02em;
color: var(--g6);
margin-top: 8px;
}

.texttabPrice{
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 140%;
letter-spacing: 0.0em;
color: var(--g8);
margin-top: 8px;
}

.texttabSpan{
padding: 1px 5px;
background: rgba(255, 0, 230, 0.1);
border: 2px solid #000000;
box-shadow: 1px 2px 0px #000000;
border-radius: 190px;
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 123%;
color: #000000;
margin: 0 0 2px;
}

.grayBackHover:hover{
  background: var(--g05);
}

.lineCheckBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: 6px 6px 6px 6px; 
  border-bottom: 1px solid var(--g05);
  background: var(--back);
  cursor: pointer;
  transition: all 0.2s ease;
}

.lineCheckBox.active, .lineCheckBox.active:hover{
  background: var(--primary);
}

.lineCheckBox.disable, .lineCheckBox.disable:hover{
  opacity: .8;
}

.lineCheckBox:hover{
  background: var(--g01);
}

.lineCheckBoxTitle{
  font-size: 18px; 
  margin-left: 10px;
  color: var(--g4);
}

.lineCheckBoxTitle.active{
  font-weight: 700;
  color: var(--text);
}

.lineCheckBoxDesc{
  font-size: 14px; 
  margin: 0 10px;
  color: var(--g3);
}




</style>