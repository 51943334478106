import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

export const CopyStore = defineStore('copy', {
  state: () => ({ 
    list : useStorage('copy.mine', []), //my copies
    download : useStorage('copy.download', []),
  }),
  
  getters: {
    // id: (state) => state.me.id,
    // admin: (state) => state.me.meta ? state.me.meta.admin : false,
  },
  
  actions: {

    // design math
      async design(copy){      
        // 
    },

     
  },
})

