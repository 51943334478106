// v-dragover="{back:'red', border:'1px solid blue'}"
const DragOver = {
  beforeMount(el, binding) {   
    
    const original = el.style.cssText
    
    el.addEventListener("dragover", (event) => {
      event.preventDefault();
      el.style.background = binding.value.background
      el.style.border = binding.value.border
    });

    el.addEventListener("dragleave", (event) => {
      event.preventDefault();
      el.style.cssText = original      
    });
 }
}
export default DragOver