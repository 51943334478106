<script setup />

<template>
  <div class="box">
    <img class="flag pointer click" :src="`/images/icons/flags/round/${flagIcon}.svg`" alt="" :style="{height: width, width: width}">
    <select class="select noselect pointer" v-model="$i18n.locale" @change="change" :style="{height: width, width: width}">
        
      <option v-for="(lang, i) in Object.keys($i18n.messages)" :key="i" :value="lang">
        {{$t(`flag.${lang}`)}} {{$t(`lang.${lang}`)}} 
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "locale-changer",
  data() {
    return {
    //   langs: [ 'en', 'ru'],
    
    };
  },
  props:{
    width: {type: String, default:'24px'},
  },
  computed: {
    flagIcon(){
        return this.db.dict.langflags[this.$i18n.locale]
    }
  },
  methods:{
    change(){
       this.db.settings.lang = this.$i18n.locale
    }
  }
};
</script>
<style scoped>

.box{
    display: flex;
    position: relative;
    flex-direction: column;

}
.select {
    width: 24px;
    height: 24px;
  padding: 0px;
  font-size: 16px;
  border: 0px solid var(--text);
  background: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  border: none;
  outline:0px;
  left: 0;
  top: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.flag{
    /* height: 24px;
    max-height: 24px; */
    width: auto;
    border-radius: 4px;
}
</style>