import { SettingsStore } from '@/ts/store/settings';
import { defineStore } from 'pinia';

const settings = SettingsStore()
export const DictStore = defineStore('dict', {
  state: () => ({}),
  
  getters: {    
    colorList(){return ['white', 'gray', 'yellow','orange','red' ,'pink','green','teal', 'blue']},
    colorListHomePage(){return ['gray', 'green','red', 'yellow','pink', 'blue', 'orange' ,'teal']},
    colors(){     
      return {        
        light:{
          yellow: {back:'#FFF9E4', button:'#FFEFB8', border:'#FFB800',accent:'#ffb700'},
          blue:   {back:'#DCECF3', button:'#D8FAFF', border:'#FFB800',accent:'#5dbae6'},
          red:    {back:'#F2DFDF', button:'#FFCACA', border:'#FFB800',accent:'#dc7a7a'},
          green:  {back:'#D5F3DF', button:'#BBFFD6', border:'#FFB800',accent:'#81E1A7'},
          pink:   {back:'#F2E4F1', button:'#FFD7FC', border:'#FFB800',accent:'#c887c4'},
          gray:   {back:'#ffffff', button:'#e6e6e6', border:'#FFB800',accent:'#c4c4c4'},
          teal:   {back:'#DCF8F1', button:'#C0F9EB', border:'#FFB800',accent:'#5AE0BF'},
          orange: {back:'#FDEFE4', button:'#FFDEC3', border:'#FFB800',accent:'#dd885b'},
          white:  {back:'#f1f1f1', button:'#ffffff', border:'#FFB800',accent:'#c7c7c7'},
        },
        dark:{
          yellow: {back:'#2D2B28', button:'#FFB800', border:'#FFB800', accent:'#FFB800'},
          blue:   {back:'#1E2325', button:'#68CFFF', border:'#FFB800', accent:'#68CFFF'},
          red:    {back:'#282121', button:'#FF8E8E', border:'#FFB800', accent:'#FF8E8E'},
          green:  {back:'#27302A', button:'#81E1A7', border:'#FFB800', accent:'#81E1A7'},
          pink:   {back:'#2B252A', button:'#DF9ADA', border:'#FFB800', accent:'#DF9ADA'},
          gray:   {back:'#101010', button:'#ffffff', border:'#FFB800', accent:'#9b9b9b'},
          teal:   {back:'#1D2926', button:'#5AE0BF', border:'#FFB800', accent:'#5AE0BF'},
          orange: {back:'#1D1915', button:'#FFA06B', border:'#FFB800', accent:'#FFA06B'},
          white:  {back:'#1F1F1F', button:'#ffffff', border:'#FFB800', accent:'#9e9e9e'},
        },
        
      }
    },

    color(){
      return settings.dark ? this.colors.dark : this.colors.light      
    },
    
    general(){return{company: 'Meetcopy'}},
    langflags() {return {'en':'uk','ru':'ru'}},
    category() {return ["ads","accounting","art","beauty","blockchain","business","career","cinema","construction","cooking","crypto","dance","design","dev","edu","estate","finance","fitness","health","hr","invest","it","legal","managment","marketing","music","productivity","pr","psyhology","relations","science","seo","smm","sport","startup","tax","transport","travel","venture"]},
    employees() {return ["solo","nano","small","medium","big","large","top"]},
    type() {return ["guide","howto","checklist","cheat","quiz","case","surveys","info","calc","paper","ebook","reslist","course","template","freecons","trial","class","tool","report","research","worksheet","workbook","demo","discount","coupon","toolkit","recipes","cookbook","tutorial","swipefiles","transcript","catalog"]},

    field() {return ["email","name","family","job","company","employees","link","linkdin","country","state","city","whatsapp","telegram","viber","insta","facebook","industry","zip","revenue","income","phone"]},

    medium(){ return ["docs","form", "video","audio", "link"]},
    

    target() {return ["entrepreneurs","founders","ceo","cto","cfo","hr","marketers","dev"]},



    fields(){
      return {
        email: {id: 'email', type:'input', title: 'fields.email'},
        name: {id: 'name', type:'input', title: 'fields.name'},
        family: {id: 'family', type:'input', title: 'fields.family'},
        job: {id: 'job', type:'input', title: 'fields.job'},
        company: {id: 'company', type:'input', title: 'fields.company'},
        employees: {id: 'employees', type:'select', translate:'employees',options: this.employees, title: 'employees.employees'},
        link: {id: 'link', type:'input', title: 'fields.link'},
        linkdin: {id: 'linkdin', type:'input', title: 'fields.linkdin'},
        country: {id: 'country', type:'input', title: 'fields.country'},
        state: {id: 'state', type:'input', title: 'fields.state'},
        city: {id: 'city', type:'input', title: 'fields.city'},
        whatsapp: {id: 'whatsapp', type:'input', title: 'fields.whatsapp'},
        telegram: {id: 'telegram', type:'input', title: 'fields.telegram'},
        viber: {id: 'viber', type:'input', title: 'fields.viber'},
        insta: {id: 'insta', type:'input', title: 'fields.insta'},
        facebook: {id: 'facebook', type:'input', title: 'fields.facebook'},
        industry: {id: 'industry', type:'input', title: 'fields.industry'},
        zip: {id: 'zip', type:'input', title: 'fields.zip'},
        revenue: {id: 'revenue', type:'input', title: 'fields.revenue'},
        income: {id: 'income', type:'input', title: 'fields.income'},
        phone: {id: 'phone', type:'input', title: 'fields.phone'},
      }
      
          
    },

    limit(){
      return{
        file: 30_000_000,
        fileTitle:'30 Mb',
        logo: 200_000,
        logoTitle:'200 Kb',
        background: 500_000,
        backgroundTitle:'500 Kb'
      }
    },
    // fileSizeLimit(){return 30_000_000},
    // fileSizeLimitTitle(){return '30 Mb'},
    // fileTotalLimit(){return 30_000_000},
    // fileTotalLimitTitle(){return '30 Mb'},

    country(){return ["us", "uk", "de", "fr", "it", "es", "pt", "ad", "ae", "af", "ag", "ai", "al", "am", "ao", "aq", "ar", "as", "at", "au", "aw", "ax", "az", "ba", "bb", "bd", "be", "bf", "bg", "bh", "bi", "bj", "bl", "bm", "bn", "bo", "bq", "br", "bs", "bt", "bv", "bw", "by", "bz", "ca", "cc", "cd", "cf", "cg", "ch", "ci", "ck", "cl", "cm", "cn", "co", "cr", "cu", "cv", "cw", "cx", "cy", "cz", "dj", "dk", "dm", "do", "dz", "ec", "ee", "eg", "eh", "er", "et", "fi", "fj", "fk", "fm", "fo", "ga", "gd", "ge", "gf", "gg", "gh", "gi", "gl", "gm", "gn", "gp", "gq", "gr", "gs", "gt", "gu", "gw", "gy", "hk", "hm", "hn", "hr", "ht", "hu", "id", "ie", "il", "im", "in", "io", "iq", "ir", "is", "je", "jm", "jo", "jp", "ke", "kg", "kh", "ki", "km", "kn", "kp", "kr", "kw", "ky", "kz", "la", "lb", "lc", "li", "lk", "lr", "ls", "lt", "lu", "lv", "ly", "ma", "mc", "md", "me", "mf", "mg", "mh", "mk", "ml", "mm", "mn", "mo", "mp", "mq", "mr", "ms", "mt", "mu", "mv", "mw", "mx", "my", "mz", "na", "nc", "ne", "nf", "ng", "ni", "nl", "no", "np", "nr", "nu", "nz", "om", "pa", "pe", "pf", "pg", "ph", "pk", "pl", "pm", "pn", "pr", "ps", "pw", "py", "qa", "re", "ro", "rs", "ru", "rw", "sa", "sb", "sc", "sd", "se", "sg", "sh", "si", "sj", "sk", "sl", "sm", "sn", "so", "sr", "ss", "st", "sv", "sx", "sy", "sz", "tc", "td", "tf", "tg", "th", "tj", "tk", "tl", "tm", "tn", "to", "tr", "tt", "tv", "tw", "tz", "ua", "ug", "um", "uy", "uz", "va", "vc", "ve", "vg", "vi", "vn", "vu", "wf", "ws", "ye", "yt", "za", "zm", "zw"]},
    
  },
  
  actions: {

    // design({}, true)
      design(design){
        
        // empty
        if (!design) return {}

        // custom
        if (design.custom) return design
              
        // scheme
        let scheme = design.scheme || 'gray'        
        let font = settings.dark ? 'white' : 'black'
        let buttonTitle = 'black'
        let initColor = this.color[scheme] || this.color['gray']

        return {
          scheme: scheme,
          background:{
            image: undefined,
            color: initColor.back,
          },
          accent: initColor.accent,
          button:{
            background: initColor.button,
            title: buttonTitle,
          },           
          title: font,
          desc: font,
          link: initColor.accent,          
          multiply: !settings.dark,
          shadow: '.6'
        }
      },
     
  },
})

