<template>
  
    <div class="box">
      <router-link :to="to">            
      
        <h3 class="title"> <span :style="{color: accent || copy.design.accent || ''}">{{ $t('type.'+copy.type) }}:</span>  {{ copy.title }} </h3>
        <div class="row" style="margin: 8px 0;">
          <div v-if="stars" class="row" style="margin-right: 10px; margin-top: 1px;">
            <svg v-for="(item, index) in stars" :key="index" width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 0L8.5716 4.83688H13.6574L9.5429 7.82624L11.1145 12.6631L7 9.67376L2.8855 12.6631L4.4571 7.82624L0.342604 4.83688H5.4284L7 0Z" fill="#FFB800"/></svg>            
          </div>
          <p class="desc">{{ tools.ago(copy.created, copy.lang) }}</p>
        </div>
        
        <p class="line">{{ copy.line }} </p>
        <img v-if="copy.logo" :src="copy.logo" class="logo" :class="{multiply: !db.settings.dark}">
      
    </router-link>
      <slot></slot>
    </div>

</template>
<script>
export default {
  data() {return {}},
  props:{
    copy:{},
    stars: Number,
    link:String,
    accent:String,
  },  
  computed:{
    time(){
      let unix = this.copy.created
      return this.tools.ago(unix, this.copy.lang)
    },
    to(){
      return this.tools.copylink(this.copy.id, this.copy.title, this.$t('type.'+this.copy.type, this.copy.lang))
    }
    
  }
};
</script>
<style scoped>

.box{  
  margin: 20px 30px;   
  min-width: 400px;
  max-width: 290px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.title{
  color: var(--text); 
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  font-size: 44px;
  line-height: 130%;
  line-height: 110%;
  text-align: left;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}
.desc{
  font-weight: 400; 
  font-size: 12px; 
  line-height: 11px;
  margin: 2px 0 10px;
  text-align: left;
  color: var(--g3);
  
}
.line{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.03em;
  color: var(--g4);
  text-align: left;
}
.logo{
  
  margin: 10px 0 4px;
  background-blend-mode: multiply;
  mix-blend-mode: multiply;
  text-align: left;
  display: block;
  
  max-width:100px;
  max-height:30px;
  width: auto;
  height: auto;
}
</style>