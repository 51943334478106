// v-color="(event) => copy.design.accent = event"
const Color = {
  mounted(el, binding) {
      
      // el
      const body = el            
      body.style.position = 'relative'      
      
      // input
      const id = Math.round(Math.random()*1000)
      const input = document.createElement('input')
      input.type = "color";
      input.id = id.toString()
      
      // style
      input.style.position = 'absolute'
      input.style.width = '100%'
      input.style.height = '100%'
      input.style.border = '2px solid red'
      input.style.background = 'none'  
      input.style.margin = 'none'  
      input.style.padding = 'none'  
      input.style.left = 0
      input.style.top = 0
      input.style.visibility = 'hidden'

      // change
      input.onchange = (e) => {
        e.preventDefault()
        let c = e.target.value
        // console.log('color',c)
        binding.value(c) //= c //(c)
      }

      // add
      body.appendChild(input)

      // upload
      const handler = () => input.click()      
      el.addEventListener('click', handler);
 }

}

export default Color

/* 
https://stackoverflow.com/questions/71782998/vuejs3-how-to-register-a-custom-global-directive-in-a-separate-folder

const TrackDirective = {
    beforeMount: (el, binding) => {
        el.addEventListener('click', () => {
            console.info('tracking')
        })
    }
}
export default TrackDirective


import upload from "upload.js";
app.directive('upload', upload)


<template>
  <div v-track>
    click on this text
  </div>
</template>
*/